.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.update_modal_Invoice {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}
.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.banner_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  max-height: 35vh;
  overflow-x: auto;
  padding: 1rem;
}

.form-group {
  display: grid;
  gap: 0.2rem;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.form-group input {
  position: relative;
  display: block;
  width: 94%;
  height: 1.6rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
.banner_form_btns {
  display: flex;
  align-items: center;
  justify-content: right;
}
.form-group input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
.hed_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resct_header {
  flex: 1;
  text-align: center;
  margin: 0px;
  font-size: 1.1rem;
  font-weight: 500;
}

.hed_wrapper button {
  padding: 0.3rem 0.4rem;
  border: none;
  background-color: #ea4335;
  color: white;
  border-radius: 36px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.inputicon {
  position: absolute;
  top: 32px;
  left: 5px;
  color: #fbbc05;
}

.banner_e_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  color: #ffff;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  border-radius: 32px;
  cursor: pointer;
}

.error {
  bottom: -30px;
  left: 0;
  color: #ea4335;
  font-size: 12px;
}

.dropdown {
  position: absolute;
  right: 10px;
  top: 20px;
}

.dropdwons {
  font-weight: 400;
  line-height: 1.4;
  color: #474646;
  text-indent: 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
