.transport {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
}

.header {
  font-family: "Muli";
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0px;
  padding: 0.5rem;
}

.Order_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.permission_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 60vh;
}
.permission_container h2 {
  font-family: "Muli";
  font-size: 1.1rem;
  text-align: center;
  font-weight: 500;
  margin: 0px;
}
.permission_container img {
  width: 100%;
  max-width: 400px;
}

.transport_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  max-height: 50vh;
  overflow-x: auto;
  align-items: baseline;
  justify-content: baseline;
  margin: 0 2rem;
}

.transport_detail {
  display: grid;
  gap: 0.4rem;
  align-items: baseline;
  justify-content: baseline;
  background-color: #f1f6fb;
  color: #474646;
  padding: 0.8rem;
  width: 95%;
  border-radius: 10px;
  position: relative;
}
.data {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
}
.data svg {
  color: #4582f4;
  font-size: 1rem;
}
.data h3 {
  font-size: 1rem;
  margin: 0px;
  color: #474646;
  padding: 0px;
  font-weight: 500;
}
.data p {
  font-size: 0.9rem;
  margin: 0px;
  color: #474646;
  padding: 0px;
}

.Addtransport {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #4582f4;
  color: #ffffff;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border-style: none;
  outline: none;
  transition: background-color 0.3s ease-in-out;
}
.button:hover {
  background-color: #3567d9;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.form_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.form_wrapper form {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  /* border: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 1rem;
  border-radius: 0.6rem;
}

.form-control {
  display: grid;
  /* gap: 1rem; */
  width: 90%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.form-group {
  display: grid;
  width: 100%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.form-control input {
  position: relative;
  display: block;
  width: 93%;
  height: 1.6rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-group input {
  position: relative;
  display: block;
  width: 91%;
  height: 1.6rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-group select {
  position: relative;
  display: block;
  width: 98%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: 2.5rem;
  color: #474646;
  text-indent: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control select {
  position: relative;
  display: block;
  width: 98%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: 2.5rem;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control input:not(:focus) {
  background-color: #f1f6fb;
}

.form-control select:not(:focus) {
  background-color: #f1f6fb;
}

.form-control input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.form-control select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.inputicon {
  position: absolute;
  top: 31px;
  left: 10px;
  color: #fbbc05;
}
.form-control span {
  margin-top: 0.5rem;
  color: #e64646;
  font-size: 0.9rem;
  padding: none;
}
.errors {
  margin-top: 0.5rem;
  color: #e64646;
  font-size: 0.9rem;
  padding: none;
}
/* Add Transport */
.Add_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown {
  position: absolute;
  right: 6px;
  top: 20px;
}
.downdrop {
  position: absolute;
  right: 0px;
  top: 20px;
}

.dropdwons {
  font-weight: 400;
  line-height: 1.6;
  color: #474646;
  text-indent: 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #2a2d2d;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  outline: none;
  border-style: none;
  cursor: pointer;
}

.back_btn:hover {
  background-color: #ffffff;
  color: #2a2d2d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.headerItem {
  flex: 1;
  font-family: "Muli";
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0px;
  padding: 0.5rem;
}

.AddTransport_container {
  display: grid;
  gap: 1rem;
  width: 100%;
  align-items: baseline;
  justify-content: baseline;
}

.inputicons {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #4582f4;
}

.Addbutton {
  display: grid;
  align-items: center;
  justify-content: center;
}

.add {
  margin-top: 1rem;
  background-color: #4582f4;
  color: #ffffff;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border-style: none;
  outline: none;
  transition: background-color 0.3s ease-in-out;
}

.add:hover {
  background-color: #3567d9;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Actionbuttons {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  top: 30px;
  right: 10px;
}

.edit,
.delete {
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9;

  outline: none;
  transition: background-color 0.3s ease-in-out;
}
.edit {
  color: #4582f4;
  background-color: #ffff;
  border: 2px solid #4582f4;
}
.delete {
  color: #e64646;
  background-color: #ffff;
  border: 2px solid #e64646;
}

.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

#delete {
  color: #e64646;
  background-color: #ffffff;
  border: 2px solid #e64646;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  outline: none;
}
#addbt {
  color: #4582f4;
  background-color: #ffff;
  border: 2px solid #4582f4;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  outline: none;
}

/* Modal Styles   */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  animation: linear normal forwards;
  animation-name: run;
  animation-duration: 100ms;
  display: flex;
  justify-content: flex-end;
  z-index: 100 !important;
  transition: 100ms;
}
.modal-content {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  opacity: 1;
  overflow: scroll;
  min-width: 600px;
  width: fit-content;
  background: #ffffff;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-content-header {
  padding: 1rem 1.5rem;
  font-weight: 500;
  background-color: #f9fafb;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}
.customer-info {
  padding: 1rem 1rem;
  display: grid;
  gap: 1rem;
}

.close-btn {
  align-self: center;
  background-color: white;
  border: 1px solid white;
  outline: none;
  color: red !important;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
  cursor: pointer;
}

/* delet Modal */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.reject_modal_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 280px;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1100;
}

.Feildsmodal_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1100;
}

.modal_header {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}

.reject_modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal_body form {
  display: grid;
  gap: 1rem;
}

.button_group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.reject_btn {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 45%;
}

.accept_btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 45%;
}

.reject_btn:hover,
.accept_btn:hover {
  opacity: 0.8;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
