.home_wrapper {
  position: relative;
  background-color: #ffffff;
  /* min-height: calc(100vh - 182px); */
  padding: 1rem 1.5rem;
  margin-top: 3rem;
}

.home_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.subscription_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #e6e6e6;
  background-color: #d9e3eb;
  border-radius: 10px;
  padding: 1rem;
}

.denied {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0px;
  color: #ea4335;
  padding: 0px;
}

#content_text {
  font-size: 1.2rem;
  color: #000001;
  margin: 0px;
}

.subs_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  border-radius: 5px;
  color: #ffff;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

/* Permissions styles */
.permission_container {
  width: 100%;
  height: 90vh;
  background-color: #ffff;
  /* border: 1px solid #e6e6e6; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.permission_container h2 {
  font-size: 1.1rem;
  font-family: "Muli";
  margin-left: 0.5rem;
  width: 100%;
  max-width: 300px;
}

.permission_container img {
  width: 100%;
  max-width: 500px;
}
.banner_container_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90vw;
  height: 150px;
}

.banner_text {
  text-align: center;
  font-size: 1.5rem;
  margin: 0px;
  color: #7a7979;
}

/* for  empty */
.emptyaccounts {
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyaccounts p {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #2e2d2d;
}
.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #7a7979;
}

.empty_cell {
  display: grid;
  justify-items: center;
}
/*  */
.home_banner_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.home_dashbord_carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_slider {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 300px;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
}

.home_slider_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.banner_btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 3;
  align-items: center;
  justify-content: center;
}

.edit_banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: #ffff;
  border-radius: 32px;
  padding: 0.3rem;
  cursor: pointer;
}

.edit_icons {
  color: #4285f4;
  margin: 0px;
  font-size: 0.8rem;
}

#icon_text {
  font-size: 0.8rem;
  margin: 0px;
}

.home_products_container {
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0px;
  overflow: scroll;
  height: 100vh;
}

.home_groups_container {
  padding-bottom: 1rem;
}
.home_group_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.office_header {
  display: flex;
  align-items: left;
}
.b_name {
  font-size: 1rem;
  color: #2e2d2d;
  margin: 0px;
  font-family: "Muli";
}

.home_group_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#home_group_h2 {
  font-size: 1.2rem;
  margin: 0px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Muli";
  /* text-shadow: 1px 1px 1px #000; */
}

.home_group_btn {
  background-color: transparent;
  color: #4285f4;
  font-family: "Muli";
  font-size: 1rem;
  font-weight: 500;
  border-style: none;
  cursor: pointer;

  padding: 0px;
}
.empty_portal_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.home_product_container {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 280px;
  overflow-x: auto;
  gap: 2rem;
}

.home_product_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  height: 250px;
  align-items: center;
  /* justify-content: center; */
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.home_product_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 160px;
}

.home_product_image {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0px;
  /* object-fit: cover; */
}

.product_info_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

#home_product_text1 {
  font-size: 1rem;
  align-items: center;
  text-align: center;
  width: 150px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#home_product_text {
  font-size: 0.8rem;
  color: #7a7979;
  align-items: center;
  margin: 0;
}

.p_price {
  font-size: 0.9rem;
  color: #fbbc05;
  margin: 0px;
}

#home_product_text3 {
  font-size: 0.9rem;
  color: #7a7979;
  align-items: center;
  width: 140px;
  text-align: center;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
}
/* Loader  styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* --- ViewAll styles--- */

.view_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.view_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#view_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
}

.veiw_prod_container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  gap: 1rem;
}

.view_product_container {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  width: 100%;
  height: 100vh;
  max-height: 60vh;
  overflow: auto;
  gap: 2rem;
  padding: 1rem 0;
}

.view_manage_link {
  text-decoration: none;
  color: inherit;
}

.view_product_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  height: 250px;
  align-items: center;
  /* justify-content: center; */
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.view_prod_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 150px;
}

.view_product_image {
  width: 100%;
  height: 100%;
  align-items: center;
  object-fit: fill;
}

#view_product_text1 {
  font-weight: 700;
  font-size: 1rem;
  align-items: center;
  text-align: center;
  width: 150px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#view_product_text {
  font-size: 0.8rem;
  color: #7a7979;
  align-items: center;
  padding: 3px;
  margin: 0px;
}

#view_product_text3 {
  font-size: 0.9rem;
  color: #000001;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.category-form-two {
  background-color: #ffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #d5d6d7;
  border-radius: 35px;
  padding: 0.6rem 1rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.view_search {
  width: 15px;
  height: 15px;
  font-size: 0.8rem;
  /* margin-right: 0.5rem;  */
}

.category-form-two input {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
}

.view_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 10px;
}

.page-item {
  list-style: none;
  padding: 2px 8px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#view_page_count {
  font-size: 0.9rem;
}

#view_page_count .number {
  font-size: 0.8rem;
  color: #4285f4;
}

.order_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem 0; */
  margin: 0px;
}

/* ....Product styles..... */
.product_main_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.product_main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prod_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#product_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
}
.product_main_contain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border-radius: 10px;
  max-width: 600px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.product_container_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.product_container_header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30vh;
}

.prod_image_container {
  margin: 0 auto;
  width: 100%;
  min-height: 100px;
}

.prod_image {
  position: relative;
  top: 0%;
  margin: 0px;
  height: 20vh;
  background-color: #def5ff;
}

.prod_img {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 120px;
  object-fit: cover;
  z-index: 1;
}

.customArrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
}

.customArrow.next {
  right: 100px;
}

.customArrow.prev {
  left: 100px;
}

.product_info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
}

#product_text_1 {
  font-size: 1.1rem;
  align-items: center;
  text-align: center;
  width: 200px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.outtext {
  font-size: 1rem;
  color: #ea4335;
  margin: 0px;
}
#product_text_2 {
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}
.product-qty {
  font-size: 1rem;
  margin: 0px;
  color: #7a7979;
}

#product_text_3 {
  font-size: 1rem;
  background-color: #34a853;
  color: #ffff;
  border: 1.2px solid #34a853;
  padding: 0.5rem 2rem;
  border-radius: 35px;
  margin: 0px;
}

#product_text_4 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  gap: 10px;
  margin: 0px;
}

.prod_details_logo {
  width: 40px;
  height: 40px;
  flex: 1;
  align-content: left;
  justify-content: center;
  margin: 0px;
}

#prod_details_text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

#prod_mesure_p {
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

#prod_units {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

.prod_btn_conatiner {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem;
}

.prod_action_delete {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 4rem;
  color: #ea4335;
  background-color: #ffff;
  border: 1.2px solid #ea4335;
  border-radius: 35px;
  cursor: pointer;
}

.prod_action_edit {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 4rem;
  background-color: #34a853;
  border: 1.2px solid #34a853;
  color: #ffff;
  border-radius: 35px;
  cursor: pointer;
}

/* .....Manage Products.... */

.Manageprod_main_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.manageprod_search_container {
  display: grid;
  grid-template-columns: 75% 23%;
  gap: 2rem;
}

.manageprod_search {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #d5d6d7;
  border-radius: 35px;
  padding: 0.8rem 0.5rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.manage_prod_search {
  width: 20px;
  height: 20px;
  font-size: 1rem;
  /* margin-right: 0.5rem;  */
}

.manageprod_search input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
  font-size: 1rem;
}

.addprod_btn {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;
}

.manageprod_addbtn {
  background-color: #34a853;
  color: #fff;
  padding: 0.8rem 0.5rem;
  font-size: 1rem;
  border: 1px solid #34a853;
  border-radius: 35px;
  cursor: pointer;
}

.manage_prodcts_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  /* padding: 0.5rem; */
}

.manageprod_statusbtn {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0.5rem;
  background-color: transparent;
  align-items: left !important;
}

.manageprod_statusbuttons {
  background-color: transparent;
  border-style: none;
  font-size: 1rem;
  /* font-weight: 600; */
  cursor: pointer;
}
.actives {
  border-bottom: 4px solid #4285f4 !important ;
}

.m_product_container {
  align-items: center;
  justify-content: center;
  padding: 0, 5rem;
  gap: 1rem;
  height: 100vh;
  max-height: 60vh;
  overflow: auto;
  padding: 1rem;
}

.manage_prod_box {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 235px;
  gap: 2rem;
  padding: 1rem 0;
}
.view_manage_link {
  text-decoration: none;
  color: inherit;
  border: 1px solid #ffff;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 260px;
  border-radius: 5px;
}

.manage_product_box {
  text-decoration-line: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;
  /* justify-content: center; */
  padding: 0.5rem;
  height: 250px;
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.manage_prod_img_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 160px;
}

.manage_prod_imge {
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  object-fit: cover;
}

#manage_product_text2 {
  font-size: 0.8rem;
  color: #7a7979;
  align-items: center;
  margin: 0;
}

#manage_product_text3 {
  font-size: 0.9rem;
  color: #7a7979;
  align-items: center;
  text-align: center;
  width: 130px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

/* ....AddProduct styles..... */

.Add_main_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_back_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
}

.Addproduct_container {
  width: 55%;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.add_register {
  display: grid;
  gap: 1rem;
  padding: 1rem;
}

.add_register input {
  width: 100%;
  border-radius: 4px;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 10px;
  overflow: hidden;
  margin: 0px;
  outline: none;
}

.add_register input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.add_register input:not(:focus) {
  background-color: #f1f6fb;
}
.info-tag {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.info-icon {
  color: #34a853;
  margin: 0px;
}
.info-tag p {
  margin: 0px;
  font-size: 0.8rem;
  color: #7a7979;
}

.add_icon {
  display: flex;
  position: relative;
}
.add_icon svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: #34a853;
}

.add_icon select {
  width: 100%;
  font-size: 1rem;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
}

.add_icon select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.add_icon select:not(:focus) {
  background-color: #f1f6fb;
}

.a_mesuring_unit select {
  position: absolute;
  right: 0px;
  /* border: none; */
  width: 130px;
  text-indent: 0px;
  align-items: center;
}
.errors {
  color: #ea4335;
  font-size: 14px;
  margin-top: 5px;
  margin: 0px;
}

.add_submit {
  width: 100%;
  background-color: #34a853;
  color: #fff;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #34a853;
  border-radius: 35px;
  cursor: pointer;
  margin: 0;
}

.add_submit:hover {
  background-color: #10722a;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.add_image_uploader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.image_array {
  display: flex;
  flex-wrap: wrap;
  flex-direction: initial;
  gap: 0.5rem;
  align-items: center;
}
.image_container {
  position: relative;
}
.imageremover {
  position: absolute;
  top: 10px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 36px;
  border-style: none;
  outline: none;
  color: #ea4335;
  cursor: pointer;
}

.file-upload-wrapper {
  border: 1px solid #ccc;
}
.file-upload-wrapper header {
  padding: 0.3rem;
  margin: 0px;
  border-bottom: 1px solid #ccc;
}
.file-upload-wrapper h3 {
  margin: 0px;
  font-size: 1.1rem;
  font-weight: 500;
}

.user_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_image_uploader {
  border: 1px solid #e6e6e6;
  border-radius: 50% !important;
  width: 100px;
  height: 100px;
  /* padding: 10px; */
  border-radius: 5px;
  cursor: pointer;
}

.user_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.uploaded_image {
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: fill;
}

.camera_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.upload_icon {
  color: #34a853;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}

#add_uploader_h3 {
  font-size: 1rem;
  font-weight: 400;
  color: #34a853;
  margin: 0px;
  padding: 0px;
}

.image_uploader {
  margin-top: 10px;
  width: 140px;
  height: 140px;
  align-items: center;
}

/* ----Delete  Product---- */

.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.delete-modal-content {
  width: 100%;
  max-width: 450px;
  background: #ffff;
  padding: 2rem 0;
  border-radius: 10px;
  text-align: center;
}

.subscription-content {
  width: 100%;
  max-width: 400px;
  height: 400px;
  background: #ffff;
  padding: 0.5rem;
  border-radius: 10px;
  text-align: center;
}

.delete-modal-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  /* margin-bottom: 10px; */
}

#delete_text1 {
  font-size: 0.9rem;
  padding: 0;
  margin: 5px 0;
}
#delete_text12 {
  font-size: 1.2rem;
  padding: 0px;
  margin: 5px 0;
  color: #ea4335;
}

#subs_header {
  font-size: 1rem;
  padding: 0px;
  margin: 5px 0;
  color: #7a7979;
}

#delete_text2 {
  font-size: 0.8rem;
  color: #555;
  margin: 10px 0;
  padding: 0;
}
.delete_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.delete_cbtn,
.delete_dbtn {
  padding: 0.5rem 2.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  border-radius: 32px;
}

.delete_cbtn {
  background-color: #ffff;
  border: 1px solid #34a853;
  color: #34a853;
}

.delete_dbtn {
  background-color: #ea4335;
  color: #fff;
  border: 1px solid #ea4335;
}

.home_wrapper.blur {
  filter: blur(5px);
}

/* ----Order Status styles--- */

.Order_container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid #fafafa; */
  background-color: #ffffff;
  /* box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  padding: 20px;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.order_status_select {
  display: none;
}

.Order_statusbtn {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: flex-start; /* Align items to the start */
  gap: 1.5rem;
  width: auto; /* Allows the container to adjust width dynamically */
  max-width: 100%; /* Prevents the container from exceeding the width of its parent */
  overflow-x: auto; /* Enables horizontal scrolling if needed */
  white-space: nowrap; /* Prevents wrapping of child elements */
}

.order_statusbuttons {
  background-color: white !important;
  color: #2e2d2d;
  background-color: #fff;
  cursor: pointer;
  border-style: none;
  font-size: 1rem;
  min-height: 30px;
  padding: 0px;
}

.order_statusbuttons:hover {
  border-bottom: 3px solid #4285f4;
}

.order_statusbuttons.active {
  background-color: #4285f4;
  border-bottom: 3px solid #4285f4;
}

.Order_table_container {
  width: 100%;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 65vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Order_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
  /* height: 76vh; */
}

.P_table {
  width: 30%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.P_table th {
  padding: 1rem;
  background-color: #4285f4;
  color: #fff;
  font-size: 1rem;
}
.P_table td {
  padding: 0.9rem;
}

.P_table td {
  color: #7a7979;
  font-size: 0.9rem;
  cursor: pointer;
}

.P_table tr {
  border: 1px solid #ddd;
}

.buyer_table {
  width: 130%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.buyer_table th {
  padding: 1rem;
  background-color: #4285f4;
  color: #ffff;
  font-size: 1rem;
}
.buyer_table td {
  padding: 0.9rem;
}

.buyer_table td {
  color: #7a7979;
  font-size: 0.9rem;
  cursor: pointer;
}

.buyer_table tr {
  border: 1px solid #ddd;
}

.Order_table th {
  padding: 0.6rem;
  background-color: #4285f4;
  color: #fff;
  width: 150px;
  font-size: 1rem;
  font-weight: 100;
}

.Order_table td {
  padding: 0.9rem;
}

.Order_table td {
  color: #212121;
  font-size: 0.9rem;
  cursor: pointer;
}

.Order_table tr {
  border: 1px solid #ddd;
}
.highlight-row td {
  background-color: #fd0e35;
  color: #ffffff;
}

.orderbyId_btn {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 0.9rem;
}

.send_remainder {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
}

/* order details  --styles */

.order_d_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  /* border: 1px solid #e6e6e6; */
  background-color: transparent;
  overflow: auto;
}
.approval_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: baseline;
  margin: 1rem 2rem;
}
.approvals {
  display: flex;
  gap: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.radious_wrapper {
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
  color: #ffff;
  border-radius: 10px;
  border-bottom-right-radius: 70px;
}

.text_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.text_container p {
  margin: 0px;
  color: #2e2d2d;
  font-size: 1.1rem;
  font-weight: 500;
}
.iconwrapper {
  size: 1.5rem;
}

.order_box_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.order_s_box {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.order_image_container {
  width: 50px;
  height: 50px;
  padding: 5px;
  margin: 0px;
  align-items: center;
  justify-content: center;
}
.order_image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

#orders_status {
  display: flex;
  flex-direction: column;
  gap: 0.5px;
  align-items: left;
  margin: 0px;
}

#order_d_header {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
  color: #2e2d2d;
}

#order_d_date {
  font-size: 0.8rem;
  margin: 0px;
  color: #7a7979;
}

.order_d_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem;
}

.order_approve {
  border: 1px solid #34a853;
  background-color: #34a853;
  color: #ffff;
  font-size: 1rem;
  border-radius: 30px;
  padding: 0.4rem 2rem;
  cursor: pointer;
}

.order_reject {
  border: 1px solid #ea4335;
  background-color: #ea4335;
  color: #ffff;
  font-size: 1rem;
  border-radius: 30px;
  padding: 0.4rem 2rem;
  cursor: pointer;
}

.order_approve:hover,
.order_reject:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.p_payments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: left;
  justify-content: left;
  padding: 0 1rem;
}
.p_header {
  font-size: 1.1rem;
  font-weight: 400;
  color: #4285f4;
  margin: 0px;
}
.p_payment-header {
  top: 0;
  background-color: #fff;
}

.order_address {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  align-items: left;
  border-bottom: 1px solid #e6e6e6;
}

.order_addres {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e6e6e6;
}

.ordered_by {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#order_d_h2 {
  color: #2a2d2d;
  font-size: 1.1rem;
  font-weight: 500;
  /* padding: 10px; */
  margin: 0px;
}

#order_addres_text {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0px;
  margin: 0px;
}
.location_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.order_d_icon {
  color: #4285f4;
  font-size: 1rem;
}

.icon_text_order {
  display: flex;
  align-items: left;
  justify-content: center;
  margin: 0px;
  font-size: 0.9rem;
  color: #7a7979;
}

.order_products {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
}
.transportData {
  display: grid;
  gap: 0.5rem;
  margin: 0 0.5rem;
  border-bottom: 1.5px solid #dddd;
}

.transport_header {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
}
.transport_head_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.downbt {
  border: 2px solid #4285f4;
  background-color: #ffffff;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
  padding: 0.2rem 1rem;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
}

.transport_details {
  display: grid;
  gap: 0.5rem;
  transition: width 0.2s ease;
}

.transport {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transport_label {
  font-size: 1rem;
  font-weight: 500;
  color: #2e2d2d;
  margin: 0px;
}

.transport_value {
  font-size: 1rem;
  font-weight: 500;
  color: #7a7979;
  margin: 0px;
}

.discount_tag {
  display: flex;
  align-items: center;
  height: 0.7rem;
  gap: 0.3rem;
  padding: 0.3rem;
  background-color: #4285f4;
  border-radius: 5px;
}
.dicount_pricetag {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}
.Mdicount {
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffff;
  margin: 0px;
  text-decoration: line-through 1.5px solid #474646;
}
.Adicount {
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffff;
  margin: 0px;
}

#order_prod_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2a2d2d;
  padding: 5px;
  margin: 0px;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
}

.qty_edit {
  border: none;
  background-color: #4285f4;
  color: #ffff;
  border-radius: 10px;
  padding: 0.3rem;
  font-size: 0.8rem;
  margin: 0px;
  cursor: pointer;
}
.qty_edit:hover {
  background-color: #2b6dd6;
  color: #ffff;
}

.order_prod_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  margin: 0px;
}

.order_prod_box {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin: 0px;
  padding: 0.5rem;
}

.order_prog_img_container {
  width: 5rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #ffffff;
  margin: 0px;
}

.order_prod_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.order_prod_info {
  display: flex;
  flex-direction: column;
  gap: 0.2px;
  margin: 0px;
  padding: 0px;
  align-items: left;
}

#order_prod_name {
  font-size: 1.05rem;
  font-weight: 500;
  color: #2e2d2d;
  margin: 0px;
}

#order_prod_incoice {
  font-size: 0.8rem;
  color: #7a7979;
  margin: 0px;
}

.order_prod_Qty {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  font-size: 0.9rem;
  color: #2e2d2d;
  margin: 0px;
}

.order_prod_Qty span {
  font-size: 0.9rem;
  font-weight: 500;
  color: #fbbc05;
  margin: 0px;
}
.effectedprod_Price {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 0.2rem;
  font-size: 1rem;
  margin: 0px;
}
.effectedprod_Price p {
  font-weight: 500;
  color: #fbbc05;
  margin: 0px;
}
.order_prod_price {
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  gap: 0.2rem;
  margin: 0.2rem;
}

.order_d_price {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0px;
  align-items: center;
  justify-content: right;
}

#order_price_container {
  font-size: 0.9rem;
  font-weight: 500;
  color: #7a7979;
  margin: 0px;
  text-align: right;
}

.order_rupee {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: center;
  justify-content: right;
}
.Originalpice {
  color: #2e2d2d;
  font-size: 0.9rem;
  margin: 0px;
  text-decoration: line-through 1.5px solid #474646;
}

.Discountpice {
  color: #fbbc05;
  font-size: 0.9rem;
  margin: 0px;
}
#order_gst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin: 0px;
  color: #7a7979;
  font-size: 0.8em;
}

#order_discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin: 0px;
  color: #34a853;
  font-size: 0.8rem;
  /* font-weight: 600; */
}

.Order_prices {
  display: grid;
  gap: 0.2rem;
  border-bottom: 1px solid #e6e6e6;
}

.order_total {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  margin: 0px;
}

#order_text_ {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2e2d2d;
  margin: 0px;
}

.order_discount {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0px;
  align-items: end;
  justify-content: right;
}
.order_flow_info {
  display: grid;
  gap: 1rem;
}

.orderHeader_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1.5px solid #dddd;
}
.FinalQD_price_wrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.LodingDate_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0px;
}
.editLoding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0px;
}

.QD_FinalMain_rwapper {
  display: grid;
  gap: 0.5rem;
}
.Sub-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
}
.FinalQd-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FinalQd-Wrapper button {
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 36px;
  outline: none;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
}

.orderHeader_wrappe {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  padding: 5px;
}
.QDNotes {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: #2e2d2d;
}

.QD_products {
  display: grid;
  gap: 0.6rem;
}
.EffectedProducts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid #dddd;
}
.prices_box {
  display: grid;
  gap: 0.3rem;
}

.dropdown {
  position: absolute;
  right: -5px;
  top: 66%;
}

.dropdwons {
  font-weight: 400;
  line-height: 1.4;
  color: #474646;
  text-indent: 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.orderheaders {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: #2a2d2d;
}
.ordervalues {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: #707979;
}

.ordernote {
  font-size: 0.9rem;
  color: #707979;
  align-items: center;
  /* text-align: center; */
  width: 400px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.FinalPrice-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FinalPrice-wrapper button {
  background-color: #4285f4;
  color: #ffff;
  font-size: 1rem;
  border-style: none;
  border-radius: 36px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
}

/* miller record Number */
.record_wrapper {
  display: grid;
  padding: 0.5rem;
}
.record_wrapper form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.record_wrappers {
  display: grid;
  padding: 0.5rem;
}

.record_wrappers form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-control {
  display: grid;
  width: 30%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.form-controls {
  display: grid;
  width: 98%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}

.form-control input {
  position: relative;
  display: block;
  width: 80%;
  /* height: 1.6rem; */
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
.form-controls textarea {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control input:not(:focus) {
  background-color: #f1f6fb;
}

.form-controls textarea:not(:focus) {
  background-color: #f1f6fb;
}

.form-control input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.form-controls textarea:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
.addRecords {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #34a853;
  color: #ffff;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 10%;
}

/* Transport Model */
.Add_Transport_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#order_price_rupee {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: 1.1rem;
  color: #fbbc05;
  margin: 0px;
}
#normal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: 1rem;
  color: #2a2d2d;
  text-decoration: line-through 1.5px solid #2e2d2d;
  margin: 0px;
}

.discount_bttton {
  display: flex;
  align-items: center;
  justify-content: right;
  border: none;
  background-color: #ffffff;
  color: #34a853;
  border: 2px solid #34a853;
  border-radius: 5px;
  margin: 0px;
  padding: 0.2rem;

  font-size: 0.9rem;
  cursor: pointer;
}
.discountprice {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0px;
  padding: 0px;
  color: #34a853;
  font-size: 1rem;
}
.Invoice-Prices {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: right;
  gap: 0.5rem;
  font-size: 1rem;
  color: #7a7979;
}
.Invoice-Prices p {
  margin: 0px;
}

.order_d_payments {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 5px;
  border-bottom: 1.5px solid #dddd;
}

#order_paments_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2e2d2d;
  margin: 0px;
}

.order_payment_lists {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: opx;
}

.from_address_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.order_from_addres {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  margin: 0px;
  color: #2a2d2d;
}

.order_from_addres button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.5rem;
  border: none;
  background-color: #ffff;
  border: 2px solid #34a853;
  color: #34a853;
  border-radius: 5px;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
}

.payment_headers {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  border-bottom: 1.3px solid #dddd;
}

/* Commisions  styles */
.commissions-pending {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}

.payements-pending {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #fbbc05;
}

.commissions {
  display: grid;
  gap: 1rem;
}
.payment_header {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  border-bottom: 1.5px solid #dddd;
}
.commission-details {
  display: grid;
  gap: 0.5rem;
  /* border-bottom: 1.5px solid #dddd; */
}

.commision_header {
  font-size: 1rem;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
  color: #2e2d2d;
  margin: 0px;
}

.commision_header::before,
.commision_header::after {
  content: "";
  display: block;
  height: 1px;
  background-color: currentColor;
}

.commisions_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payes-names {
  display: grid;
  gap: 0.4rem;
}

.payes-names h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2a2d2d;
  margin: 0px;
}

#header_payments {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
}
.subdata {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.9rem;
  margin: 0px;
}

.subdata p {
  color: #fbbc05;
  margin: 0px;
  font-size: 1rem;
}

.payment-date {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.subheader {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}
.golde {
  font-size: 1rem;
  color: #707979;
  margin: 0px;
}

.payment_data {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin: 0px;
}

.payment_item {
  display: grid;
  gap: 0.3rem;
  margin: 0px;
}
.payment_item h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #707979;
  margin: 0px;
}
.orderpay {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  gap: 0.2rem;
  color: #7a7979;
  margin: 0px;
}

.orderpay span {
  font-size: 0.9rem;
  color: #fbbc05;
  margin: 0px;
}
#payment_item {
  display: grid;
  gap: 0.3rem;
  margin: 0px;
  color: #7a7979;
}
.payment_status_ckeck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}
.payment_staus {
  font-size: 1rem;
  color: #2e2d2d;
  margin: 0px;
}
.p_s {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

.payment_add_btn_c {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #e6e6e6; */
  padding: 0.5rem;
}

.payment_add_btn {
  border: none;
  border-bottom: 2px solid #4285f4;
  font-size: 1.1rem;
  color: #4285f4;
  background-color: transparent;
  cursor: pointer;
}

.order_update_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 0px;
}
.order_update_container {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
}

#order_Status {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2a2d2d;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

#order_update_status {
  font-size: 1.2rem;
  color: #7a7979;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

.order_remainder {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.order_remainder_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  padding: 0.5rem 3rem;
  border-radius: 32px;
  font-size: 1rem;
  cursor: pointer;
}

.order_remainder_btn:hover {
  background-color: #3978dc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.reorder_button {
  border: 1px solid #34a853;
  color: #ffff;
  background-color: #34a853;
  font-size: 1rem;
  border-radius: 32px;
  padding: 0.5rem 3rem;
  cursor: pointer;
}

/* Reject model  box */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.reject_modal_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 280px;
  height: 250px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  /* align-items: center; */
}

#resct_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}

#reject_text {
  font-size: 0.8rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}

.reject_input_modal {
  /* width: 100%; */
  height: 100px;
  align-items: center;
  text-indent: 1rem;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
}

.disabled {
  color: #7a7979;
}

.reject_model_buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
}

.reject_model_ctn {
  border: 1px solid #34a853;
  background-color: #ffff;
  font-size: 1.1rem;
  color: #34a853;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
}

.reject_model_btn {
  border: 1px solid #ea4335;
  background-color: #ea4335;
  color: #ffff;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
}

.Update_P_model_btn {
  border: 1px solid #4285f4;
  color: #ffff;
  justify-content: last baseline;
  font-size: 1.2rem;
  background-color: #4285f4;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
}
.close_modal {
  position: absolute;
  background-color: #ea4335;
  color: #aaa;
  top: 0px;
  right: 0%;
  padding: 4px 6px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  border-start-end-radius: 10px;
}

/* AddPayment */
.update_modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 550px;
  height: 450px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}

.Banner_Modal_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;
  /* height: 350px; */
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}

.Banner_Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.payment_modal_order {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 400px;
  height: 400px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}

.update_modal_Invoice {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}

.update_modal_QD {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  /* height: 400px; */
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}
.QD_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.QD_header h2 {
  flex: 1;
  text-align: center;
}
.QD_header button {
  background-color: #ea4335;
  color: #ffffff;
  padding: 4px 5px;
  border-radius: 30px;
  cursor: pointer;
  border: 1.5px solid #ea4335;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
.update_payment_container1,
.update_payment_container2 {
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.update_input_model1,
.update_input_model2 {
  width: 280px;
  font-size: 1rem;
  text-indent: 2rem;
  margin: 0px;
  height: 35px;
  border: 1px solid #4285f4;
  position: relative;
  border-radius: 5px;
  position: relative;
  outline: none;
}

.update_d_icon,
.update_r_icon {
  position: absolute;
  left: 5px;
  top: 10px;
  color: #4285f4;
  font-size: 1.1rem;
}

.error_message {
  position: absolute;
  bottom: -30px;
  left: 0;
  color: #ea4335;
  font-size: 12px;
}

/* SalesStatus styles    */
.Status_order_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Add_main_container_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.salestatues_button_arrow:hover {
  border: 1px solid #4285f4;
}

.salesstatues_buttons_arrow_downloads {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.salestatues_button_arrow,
.salestatues_button_downloads {
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.salestatues_button_downloads svg {
  margin-right: 5px;
}

.salesstatues_buttons_arrow_one {
  display: flex;
  justify-content: flex-start;
}

.salestatues_button_arrow {
  background-color: #fff;
  border: none;
  padding: 10px;
}
.salestatues_button_downloads {
  background-color: #4285f4;
  float: right;
  color: #fff;
  border: none;
  padding-left: 25px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}

.salesstatues_buttons_arrow_downloads {
  width: 50%;
}

.sales_main_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  overflow: auto;
}

.sales_graph_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 1rem;
  padding: 0 1rem;
}
.sales_graph_container button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #4285f4;
  color: #ffffff;
  border-style: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
.sales_form {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 1rem;
  align-items: center;
  justify-content: right;
}

.label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0px;
  color: #4285f4;
}

.form_data {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 1rem;
  padding: 1rem;
}
.form_data input {
  width: 100%;
  max-width: 200px;
  font-size: 1rem;
  padding: 0.5rem;
  height: 30px;
  box-shadow: #4285f4;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}
.form_data .select_type {
  width: 100%;
  height: 50px;
  box-shadow: #4285f4;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.form_sales_b {
  display: flex;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.sales_s_btn {
  text-align: center;
  font-size: 1rem;
  border: 1px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  padding: 1rem 0.5rem;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales_data_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#sale_header {
  font-size: 1.1rem;
  font-weight: 400;
  color: #4285f4;
  margin: 0px;
}

.salesdat1,
.salesdat2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: left;
  margin: 0px;
}

.regional_sales {
  max-width: 400px;
  border: 1px solid #000;
  height: 300px;
  padding: 0.5rem;
  margin: 0.5rem;
}

/* User Profile   Styles */

.profile_container {
  display: grid;
  grid-template-columns: 25% 60%;
  gap: 2rem;
  min-height: 70vh;
}

.profile_information_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
  background-color: #ffff;
  border-radius: 5px;
  border: 2px solid #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.profile_info_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_image_container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0 10px;
}

.user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}
.user_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home_user_name {
  font-size: 0.8rem;
  margin: 0px;
  color: #423f3a;
}

.home_user_num {
  font-size: 0.8rem;
  padding: 5px 0;
  margin: 0px;
  color: #423f3a;
}

.profile_dashbord {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin: 0px;
  min-height: 55vh;
  overflow: auto;
}

.profile_p_header {
  font-size: 0.8rem;
  font-weight: 700;
  color: #7a7979;
  text-align: left;
  margin: 0px;
}

.profile_btn {
  display: flex;
  flex-direction: row;
  background-color: #ffff;
  gap: 0.3rem;
  align-items: center;
  padding: 0.5rem 0.1rem;
  margin: 0px;
  border-style: none;
  cursor: pointer;
  border-radius: 5px;
}

.profile_btn:hover {
  background-color: #f1f6fb;
  color: #4285f4 !important;
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.profile_icon {
  color: #fbbc05;
  font-size: 1rem;
  margin: 0px;
}

.text_header {
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
}

/* profile button */
.permission_btn {
  position: absolute;
  right: 0px;
  top: 5px;
  align-items: center;
  justify-content: center;
}
.switch_btn {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 25px;
}

.switch_btn input {
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 23px;
  left: 4px;
  bottom: 2px;
  background-color: #ffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fbbc05;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fbbc05;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile_update_container {
  position: relative;
  overflow: auto;
  background-color: #ffff;
  border-radius: 5px;
  border: 2px solid #ffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.edit_profile {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  position: relative;
}
.edit_profile_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_profile input {
  width: 100%;
  border-radius: 4px;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 10px;
  overflow: hidden;
  margin: 0px;
  outline: none;
}

.edit_profile input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.edit_icon {
  display: flex;
  position: relative;
}

.edit_icon svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: #4285f4;
}
.edit_btn_container {
  display: flex;
  align-self: center;
  justify-content: center;
  max-width: 300px;
  position: relative;
}

.edit_profile_submit {
  width: 100%;
  background-color: #4285f4;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1rem;
  border: 1px solid #4285f4;
  border-radius: 5px;
  padding: 0.5rem 6rem;
  cursor: pointer;
  margin-top: auto;
}

.edit_profile_submit:hover {
  background-color: #4285f4;
}

.active_button_type {
  background-color: #f1f6fb;
  color: #4285f4 !important ;
}

/* Timings styles*/
.times_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f1f6fb;
  padding: 1rem;
  text-align: center;
}

.times_header {
  text-align: center;
  font-size: 1.2rem;
  margin: 0px;
}

.times_box_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.times-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center; /* Center the content within the box */
  align-items: center;
  /* vertical-align: center; */
}

.weak_day {
  font-size: 1rem;
  color: #616060;
  max-width: 7rem;
  width: 100%;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

.weak_day_time {
  font-size: 1rem;
  color: #616060;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.edit_time {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time_edit_btn {
  border: none;
  color: #4285f4;
  background-color: transparent;
  font-size: 1rem;
}
.time_p {
  font-size: 0.7rem;
  margin: 0px;
}
.time_form_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 200px;
  padding: 1rem;
}
.time_form_box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.1rem;
}
.picker {
  position: relative;
}
.picker select {
  outline: none !important;
  position: absolute !important;
}
.time_label1,
.time_label2 {
  font-size: 0.8rem;
  color: #2a2d2d;
  margin: 0px;
}
.from_input,
.to_input {
  text-indent: 1rem;
  width: 200px;
  height: 30px;
  font-size: 1rem;
}

/* My subscriton styles */
.mysubscription_container {
  padding: 1rem;
}

/* Suppots styles */
.Support_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  height: 70vh;
  overflow: auto;
}

.Support_statuses {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.Status_all {
  border: none;
  background-color: transparent;
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 4rem;
  cursor: pointer;
}

.active {
  border-bottom: 3px solid #4285f4 !important ;
}

.tickects_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: scroll;
}

.Nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#Nodata2 {
  font-size: 1rem;
  margin: 0px;
  color: #7a7979;
}

.tickect_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.tickets {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.ticket_header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0px;
}

.ticket_header_title {
  font-size: 1rem;
  color: #2a2d2d;
  margin: 0px;
}

.tickect_desc {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}

.tickect_timezone {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}

.tickect_status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Resolved {
  font-size: 1rem;
  margin: 0px;
  background-color: #d6ffe1;
  color: #34a853;
  padding: 0.3rem 1rem;
  border-radius: 32px;
}

.Open {
  font-size: 1rem;
  margin: 0px;
  background-color: #ffdfdd;
  color: #ea4335;
  padding: 0.3rem 1rem;
  border-radius: 32px;
}

.create_ticket {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_new_ticket {
  align-items: center;
  border: 1px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

/*  */
.g_ticket_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.g_ticket_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

.g_Ticket_header {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0px;
}

.g_ticket_header_text {
  font-size: 1rem;
  color: #2a2d2d;
  margin: 0px;
}

.g_ticket_time {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}

.g_Ticket_s {
  display: flex;
  align-items: center;
  justify-content: center;
}

.g_ticket_desc {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.g_desc_header {
  font-size: 1rem;
  color: #2a2d2d;
  margin: 0px;
}

.g_dec_text {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}

.g_ticket_img_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid #e6e6e6;
}

.g_images_header {
  font-size: 1rem;
  color: #2a2d2d;
  margin: 0px;
}

.g_img_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border-radius: 5px;
  width: 80px;
  height: 80px;
}

.g_image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  margin: 0px;
}

.g_report_details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.g_report_text_header {
  font-size: 1rem;

  margin: 0px;
  color: #2a2d2d;
}

.g_t_user_d {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.g_t_c_name {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}
.g_t_name {
  font-size: 0.9rem;

  color: #7a7979;
  margin: 0px;
}

.g_t_update_time {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.g_t_r_header {
  font-size: 0.9rem;
  color: #2a2d2d;
  margin: 0px;
}

.g_t_resoved_time {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}
.message-remark {
  font-size: 0.9rem;
  color: #34a853;
  margin: 0px;
}

/*  Faqs styles */
.faqs_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

/* about container */
.about_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.about_image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
}

.about_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #7a7979;
}

/* Terms & conditions */
.terms_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

/* Banners  styles*/
.banner_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #ffff;
  border-radius: 10px;
}
.banners_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
}

.banner_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.banner_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  height: auto;
}

.banner_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.qty_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow: scroll;
  height: 100vh;
}

.banner_d_btn {
  border: 1px solid #ea4335;
  background-color: #ea4335;
  color: #ffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

/* modal styles */

.overlaye {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.banner_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  max-height: 50vh;
  overflow: auto;
  padding: 1rem;
}

.banner_input input[type="file"] {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 40px;
  border: 1px solid #4285f4;
}
.qty_input {
  width: 60px;
  height: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.banner_form_btns {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}

.banner_e_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  color: #ffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}
.Quantity_d_wrapper {
  display: grid;
  gap: 0.5rem;
}
.d_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
}
.d_wrapper button {
  color: #ffffff;
  cursor: pointer;
  background-color: #ea4335;
  border: 1.5px solid #ea4335;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
}
.d_wrapper button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #ffffff;
}

.Qty_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resct_he {
  flex: 1;
  text-align: center;
  margin: 0px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #7a7979;
}

/* Add discount */
.banner_i_container input {
  width: 100%;
  height: 100px;
  text-indent: 0.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.banner_input input {
  width: 100%;
  height: 1.8rem;
  text-indent: 0.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.cancel_form_btns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: right;
}

.cancel_s_btn {
  border: 1px solid #ea4335;
  background-color: #ea4335;
  color: #ffff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
}

.cancel_c_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  color: #ffff;
  font-size: 1rem;

  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
}
/* Individual data */
.indiviualreport {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.IndiviualContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Indiviualform {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.idh4 {
  margin: 0px;
  font-size: 1rem;
  color: #4285f4;
  padding: 0px;
}

.nodatatext {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin-left: 2rem; */
}
.salestBtn {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin: 0px;
}
.colorbtn {
  background-color: #8884d8;
  padding: 6px;
  border-style: none;
}

.Paymentfrom-wrapper {
  display: grid;
  gap: 1rem;
  width: 100%;
}
.Paymentfrom-wrapper form {
  display: grid;
  gap: 1rem;
  align-items: baseline;
  justify-content: baseline !important;
}

.form-group {
  display: grid;
  gap: 0.2rem;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.form-group input {
  position: relative;
  display: block;
  width: 94%;
  height: 1.8rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-group select {
  position: relative;
  display: block;
  padding: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-group input:focus,
.form-group select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
.Qualityproduct_wrapper {
  display: grid;
  gap: 0.2rem;
}
.Qualityproduct_wrapper h3 {
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  color: #2e2d2d;
}

.QD_Product_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.Qd_product_info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.Qd_product_info p {
  margin: 0px;
  font-size: 0.9rem;
  color: #2e2d2d;
}

.inputicon {
  position: absolute;
  top: 11px;
  left: 10px;
  color: #4285f4;
}
.checkproduct {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
}
.Qualityform {
  display: grid;
  gap: 1rem;
}

.checkproduct input {
  width: 20px;
  height: 20px;
  margin: 0px;
  cursor: pointer;
}

.form-Quality {
  display: grid;
  gap: 0.5rem;
}

.form-Quality label {
  margin-right: 0.5rem;
}

.form-Quality textarea {
  width: 95%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
}

.form-Quality textarea:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.form-Quality textarea:not(:focus) {
  background-color: #f1f6fb;
}

@media screen and (max-width: 900px) {
  .home_wrapper {
    width: calc(100% - 32px);
    position: relative;
  }

  .home_dashbord_carousel {
    max-width: 100vw;
    justify-content: center;
    align-items: center;
  }

  /*  */
  .manageprod_search_container {
    display: grid;
    grid-template-columns: 66% 30%;
    gap: 1rem;
  }

  .manageprod_search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    padding: 0.4rem 0.4rem;
  }

  .manage_prod_search {
    width: 10px;
    height: 10px;
    font-size: 0.8rem;
  }

  .manageprod_search input {
    width: 100%;
    font-size: 0.7rem;
  }

  .manageprod_addbtn {
    font-size: 0.7rem;
    align-items: center;
    justify-content: center;
  }

  .view_pagination {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .sales_main_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .sales_analysis {
    max-width: 400px;
    overflow-x: scroll;
    object-fit: fill;
  }

  .regional_sales {
    max-width: 400px;
    overflow-x: scroll;
    object-fit: fill;
  }
}

@media screen and (max-width: 800px) {
  .profile_container {
    display: grid;
    grid-template-columns: 15% 85%;
  }
  .profile_information_container {
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .profile_btn:hover {
    background-color: #f1f6fb;
    color: #4285f4;
    width: 20px;
    height: 20px;
  }

  .profile_info_container {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
  }

  .profile {
    font-size: 1.1rem;
    font-family: "MUli";
    margin: 0px;
    color: #2e2d2d;
    text-align: center;
  }
  .permission_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }

  .user_image_container {
    display: none;
  }
  .user_info {
    display: none;
  }

  .user_initial {
    font-size: 1.1rem;
  }

  .profile_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  .profile_btn:hover {
    background-color: #f1f6fb;
    color: #4285f4;
  }

  .profile_icon {
    font-size: 1rem;
  }

  .text_header {
    display: none;
  }

  .profile_update_container {
    position: relative;
  }

  .edit_profile {
    gap: 1rem;
    padding: 0.5rem;
  }

  .edit_profile input {
    width: 100%;
    font-size: 0.9rem;
    padding: 8px;
  }

  .edit_icon svg {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 650px) {
  .Addproduct_container {
    width: 100%;
  }

  .profile_container {
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .profile_info_container {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
  }
  .add_register {
    padding: 1rem;
    gap: 1rem;
  }

  .Order_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .order_status_select {
    display: flex;
    float: right;
    align-content: end;
    justify-self: end;
  }
  .Order_statusbtn {
    display: none;
  }
  .Order_table_container {
    width: 100%;
  }
  .weak_day {
    font-size: 0.8rem;
  }
  .times_container {
    padding: 0.5rem;
  }

  .weak_day_time {
    font-size: 0.8rem;
  }

  .time_edit_btn {
    font-size: 0.8rem;
  }
  .profile_dashbord {
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  .prod_btn_conatiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .profile_container {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .profile_info_container {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
  }

  .prod_action_delete {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
  }

  .prod_action_edit {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
  }

  .order_d_btns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
  }

  .order_box_container {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .order_address {
    padding: 0px;
  }

  .profile_p_header {
    font-size: 0.6rem;
  }

  .times_container {
    padding: 0.3rem;
  }

  .weak_day {
    font-size: 0.7rem;
  }

  .weak_day_time {
    font-size: 0.7rem;
  }

  .time_edit_btn {
    font-size: 0.8rem;
  }
  .edit_btn_container {
    width: 100%;
  }
  .edit_profile_submit {
    border-radius: 5px;
    padding: 0.5rem 2.5rem;
  }
  .profile_dashbord {
    align-items: center;
  }
  .switch_btn {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 21px;
  }

  .slider:before {
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
  }
}
@media screen and (max-width: 768px) {
  .permission_container {
    flex-direction: row-reverse;
  }
}
