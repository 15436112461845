.home_wrapper {
  position: relative;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  margin-top: 3rem;
}
.permission_container {
  width: 100%;
  height: 90vh;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.permission_container h2 {
  font-size: 1.2rem;
  font-family: "Muli";
  width: 100%;
  max-width: 300px;
}
.permission_container img {
  width: 100%;
  max-width: 500px;
}

.Add_main_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_back_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  border-radius: 0.6rem;
  border-style: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.add_back_btn:hover {
  background-color: #ffffff;
  color: #2a2d2d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: background-color 0.3s, color 0.3s;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* border: 1px solid red; */
}

.container {
  display: grid;
  align-items: baseline;
  justify-content: baseline;
  gap: 1rem;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 1rem 2rem;
}

.datacontainer {
  display: grid;
  align-items: center;
  width: 95%;
  gap: 0.8rem;
  overflow-y: auto;
  padding: 1rem;
}

.data_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
}
.data_item:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.name {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #474646;
  text-transform: capitalize;
}

.data_item p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #474646;
  text-transform: capitalize;
}

.Orders_container {
  display: grid;
  gap: 1rem;
  pad: 1rem;
}

.order {
  display: grid;
  align-items: baseline;
  gap: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
}
.order:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.orderheader {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e6e6e6;
}
.title-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.head {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 0.5rem;
}

.head p {
  font-size: 0.9rem;
  font-family: 400;
  color: #2e2d2d;
  margin: 0px;
}

.data_info,
.delivery {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}
.delivery {
  border-bottom: 2px solid #e6e6e6;
}

.icon_containetr svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #f2f2f2;
  color: #4285f4;
  cursor: pointer;
}
.order_details {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.order_details p {
  margin: 0px;
}
.pricess {
  display: grid;
  align-items: baseline;
  justify-content: baseline;
}

.pricess p {
  margin: 0px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #7a7979;
}

.pricess h3 {
  margin: 0px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fbbc05;
}

.orderId {
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  color: #474646;
}

.emptyaccounts {
  display: grid;
  align-items: center;
  justify-content: center;
}
.emptyaccounts p {
  font-size: 1rem;
  font-weight: 500;
  color: #7a7979;
}
.approval_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.approvals {
  display: flex;
  gap: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  /* width: 100%;
  max-width: 100%; */
}
.radious_wrapper {
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
  color: #ffff;
  border-radius: 10px;
  border-bottom-right-radius: 70px;
}

.text_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.text_container p {
  margin: 0px;
  color: #2e2d2d;
  font-size: 1.1rem;
  font-weight: 500;
}

.Order_table_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 68vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Order_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.Order_table th {
  padding: 0.6rem;
  background-color: #4285f4;
  color: #fff;
  width: 150px;
  font-size: 1rem;
  font-weight: 400;
}

.highlight-row td {
  background-color: #fd0e35;
  color: #ffffff;
}
.Order_table td {
  padding: 0.9rem;
}

.Order_table td {
  /* color: #212121; */
  font-size: 0.9rem;
  cursor: pointer;
}

.Order_table tr {
  border: 1px solid #ddd;
}

.orderbyId_btn {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 0.9rem;
}

.send_remainder {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
}

.view_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 10px;
}

.page-item {
  list-style: none;
  padding: 2px 8px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#view_page_count {
  font-size: 0.7rem;
}

#view_page_count .number {
  font-weight: 600;
  font-size: 0.7rem;
  color: #4285f4;
}

.order_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem 0; */
  margin: 0px;
}

.reorder_button {
  border: 1px solid #34a853;
  color: #ffff;
  background-color: #34a853;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}

/* Loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.empty_cell {
  display: grid;
  justify-items: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

@media (max-width: 1300px) {
  .approval_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
