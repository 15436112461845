.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.Content-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

.main-content {
  height: 100%;
  background: white;
  min-height: 100vh;
  z-index: unset;
  height: 100%;
  width: calc(100% - 250px);
  overflow-y: auto;
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}
.nav-hide {
  width: calc(100% - 90px);
  margin-left: 90px !important;
}
@media screen and (max-width: 900px) {
  .Wrapper {
    grid-template-columns: 70px 1fr !important;
  }
  .main-content {
    z-index: 1;
    margin-left: 70px;
  }
  /* .nav-hide {
        margin-left: 0 !important;
    } */
}
