.TopNav {
  display: flex;
  gap: 1rem;
  background-color: #e1f2e5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.5rem 0;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */
}

.loading {
  background-color: white;
  display: grid;
  gap: 0.2rem;
  align-items: center;
  justify-items: center;
}

.headercontainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.userinter {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
}
.hitext {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2e2d2d;
  margin: 0px;
}

.nametext {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4285f4;
  margin: 0px;
}
.smileicon {
  color: #4285f4;
  margin: 0px;
}

/* font-size: 0.9rem;
  font-family: 600;
  color: #2e2d2d;
  margin: 0px; */

.container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

#line {
  text-align: center;
  font-size: 1.2rem;
  color: #7a7979;
  font-family: 600;
  margin: 0px;
}

.usermobilecontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: left;
}
.mobiletext {
  display: flex;
  align-items: center;
  margin: 0px;
  color: #2e2d2d;
  font-size: 0.9rem;
  font-weight: 600;
}
.mnumer {
  font-size: 0.9rem;
  margin: 0px;
  color: #4285f4;
}
.timecontainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.timeicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  color: #fbbc05;
}
.time {
  font-size: 0.9rem;
  color: #2e2d2d;
  margin: 0px;
  font-weight: 600;
}
@keyframes animation-dropdown-menu-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animation-dropdown-menu-move-up {
  0% {
    margin-top: 10px;
  }
  100% {
    margin: 0;
  }
}
