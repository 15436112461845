.home_wrapper {
  position: relative;
  background-color: #ffffff;
  /* min-height: calc(100vh - 182px); */
  padding: 1rem 1.5rem;
  margin-top: 3rem;
  position: relative;
}

.home_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.no_Product {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.home_products_container {
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0px;
  overflow: auto;
  /* height: 75vh; */
}

.subscription_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #e6e6e6;
  background-color: #d9e3eb;
  border-radius: 10px;
  padding: 1rem;
}

.denied {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0px;
  color: #ea4335;
  padding: 0px;
}

#content_text {
  font-size: 1.2rem;
  color: #000001;
  margin: 0px;
}

.subs_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  border-radius: 5px;
  color: #ffff;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.banner_container_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.banner_text {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px;
  color: #7a7979;
}

/* for  empty */
.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
}

.e_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  /* font-weight: 600; */
  color: #7a7979;
  padding: 0.2rem;
}
/*  */

.Order_container {
  display: flex;
  position: relative;
  flex-direction: column;
  /* gap: 1rem; */
  background-color: transparent;
}

.permission_container {
  width: 100%;
  height: 90vh;
  background-color: #ffff;
  /* border: 1px solid #e6e6e6; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.permission_container h2 {
  font-size: 1.1rem;
  font-family: "Muli";
  margin-left: 0.5rem;
  width: 100%;
  max-width: 300px;
}

.permission_container img {
  width: 100%;
  max-width: 500px;
}

.home_groups_container {
  padding-bottom: 1rem;
}
.home_group_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.home_group_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#home_group_h2 {
  font-size: 1.1rem;
  margin: 0 0.8rem;
  text-transform: initial;
  font-weight: 300;
}

.home_group_btn {
  background-color: transparent;
  color: #4285f4;
  font-family: "Muli";
  font-size: 0.9rem;
  border-style: none;
  cursor: pointer;
}

.home_product_container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 280px;
  height: auto;
  overflow: auto;
  padding: 0.3rem;
}

.home_product_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  min-height: 260px;
}

.shop_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 160px;
}

.home_product_image {
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  padding: 0px;
  object-fit: cover;
}

.product_info_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
#home_product_text1 {
  font-size: 1rem;
  align-items: center;
  text-align: center;
  width: 150px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.outtext {
  font-size: 1rem;
  font-weight: 600;
  color: #ea4335;
  margin: 0px;
}

#home_product_text {
  font-size: 0.8rem;
  color: #7a7979;
  align-items: center;
  padding: 0px;
  margin: 0;
}

#home_product_text3 {
  font-size: 0.9rem;
  color: #000001;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

/* Loader  styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* --- ViewAll styles--- */

.view_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.view_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffff;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#view_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  margin: 0px;
}
.veiw_prod_container {
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: center; */
  border: 1px solid #ccc;
  background-color: #ffff;
  border-radius: 10px;
  padding: 2rem;
  gap: 2rem;
}

.view_product_container {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  width: 100%;
  height: auto;
  gap: 1.3rem;
}
.view_manage_link {
  text-decoration: none;
  color: inherit;
}

.view_product_box {
  text-decoration-line: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 1rem; */
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: #ffff;
  border-radius: 10px;
}

.view_product_image {
  width: 160px;
  height: 160px;
  align-items: center;
  padding: 0.3rem;
  object-fit: cover;
  margin-bottom: 10px;
}

#view_product_text1 {
  font-weight: 700;
  font-size: 1rem;
  align-items: center;
  padding: 5px;
  margin: 0;
}

#view_product_text {
  font-size: 0.9rem;
  color: #7a7979;
  align-items: center;
  padding: 3px;
  margin: 0;
}

#view_product_text3 {
  font-size: 1rem;
  color: #000001;
  align-items: center;
  padding: 0px;
  margin: 0;
}

.category-form-two {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #d5d6d7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.view_search {
  width: 20px;
  height: 20px;
  font-size: 1rem;
  /* margin-right: 0.5rem;  */
}

.category-form-two input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
}

.view_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 50%;
}

.page-item {
  list-style: none;
  padding: 2px 10px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#view_page_count {
  font-size: 1rem;
  padding: 0.5rem;
}

#view_page_count .number {
  font-weight: 600;
  font-size: 0.9rem;
  color: #4285f4;
}

.manageprod_search_container {
  display: grid;
  grid-template-columns: 30%;
  /* padding: 1rem; */
}

.manageprod_search {
  background-color: #ffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #d5d6d7;
  border-radius: 35px;
  padding: 0.5rem 0.8rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.manage_prod_search {
  width: 20px;
  height: 20px;
  font-size: 1rem;
  /* margin-right: 0.5rem;  */
}

.manageprod_search input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
  font-size: 1rem;
}

/* ....AddProduct styles..... */

.Add_main_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Add_heade {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.office_header {
  display: flex;
  align-items: left;
}
.b_name {
  font-size: 1.3rem;
  color: #2e2d2d;
  margin: 0px;
  font-weight: 600;
  font-family: "Muli";
}

.add_back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 1.5rem;
  padding: 0px;
  cursor: pointer;
}

.add_back_btn:hover {
  background-color: #ffff;
  color: #2a2d2d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}
.Address_form {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Addproduct_container {
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;
  background-color: transparent;
  overflow: auto;
}

.add_register {
  pad: 2rem 2rem;
  gap: 1rem;
}
.add_register input {
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  padding: 10px;
  overflow: hidden;
}

.add_icon {
  display: flex;
  /* flex-direction: column; */
  position: relative;
}
.add_icon svg {
  position: absolute;
  top: 25px;
  left: 15px;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #34a853;
}

.add_icon select {
  width: 100%;
  max-width: 500px;
  font-size: 1.1rem;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.errors {
  color: #ea4335;
  font-size: 14px;
  margin-top: 5px;
}

.add_submit {
  width: 100%;
  background-color: #34a853;
  color: #fff;
  padding: 10px;
  font-size: 1.1rem;
  border: 1px solid #34a853;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}

.add_submit:hover {
  background-color: #34a853;
}

.add_image_uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  height: 180px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.upload_icon {
  color: #34a853;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}
.add_uploader_h3 {
  font-size: 2rem;
  color: #34a853;
  margin: 0px;
  padding: 0px;
}

/* Manufacturerpage styles */

.manufacturer_main_container {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.manufacturer_banner_image {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffff;
  height: 160px;
  margin: 0px;
  border-radius: 5px;
}

.banner_image {
  width: 100%;
  height: 300px;
  object-fit: fill;
  border-radius: 5px;
}

.manufacturer_products_container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  background-color: #ffffff;
  max-height: 75vh;
  overflow: auto;
}

.m_products_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.5rem; */
}

.m_p_totalcount {
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
  color: #4285f4;
}
.m_products_header_cart_items {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.progress_heads {
  display: flex;
  flex-direction: column;
}

.progress_heads p {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0px;
  color: #7a7979;
}

.cart_length {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

.moq_rupee {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

.active-shop-open {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

.active-shop-close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

.iconopen {
  font-size: 0.9rem;
  margin: 0px;
  background-color: #34a853;
  color: #ffff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
}

.iconclose {
  font-size: 0.9rem;
  margin: 0px;
  background-color: #ea4335;
  color: #ffff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
}

.quantity_limit {
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}

.cartItems {
  font-size: 1rem;
  margin: 0px;
  color: #0f140e;
}

.view_cart {
  display: flex;
  font-size: 1rem;
  margin: 0px;
  border: 1px solid #34a853;
  background-color: #34a853;
  color: #ffff;
  border-radius: 5px;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
}
.Shop_prodcuts_c {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  overflow: scroll;
  /* padding: 0 0.5rem; */
}

.manufacturer_products {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  overflow: auto;
  padding: 1rem;
}

.shop_product_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  width: 190px;
}

.shop_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 160px;
  padding: 0.3rem;
}

.image_shop_product {
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: 0px;
}

.shop_product_info {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: center;
  min-height: 21vh;
  height: auto;
  max-height: 100vh;
  position: relative;
}

.shop_p_weight {
  font-size: 0.8rem;
  color: #7a7979;
  margin: 0px;
}

.shop_p_price {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  text-align: center;
}

.p_price {
  color: #fbbc05;
  font-size: 0.9rem;
  margin: 0px;
}

.p_bag_qty {
  color: #2e2d2d;
  font-size: 0.9rem;
  margin: 0px;
}

.p_bag_price {
  color: #2e2d2d;
  font-size: 0rem;
  margin: 0px;
}

#s_p_price {
  font-size: 0.9rem;
  margin: 0px;
  color: #fbbc05;
}

.shop_u_count {
  display: flex;
  flex-direction: row;
  margin: 0px;
  font-size: 0.9rem;
}

#s_p_units {
  margin: 0px;
  font-size: 0.9rem;
  color: #7a7979;
}

.shop_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  justify-items: baseline;
  bottom: 5px;
}

.shop_add_cart_btn {
  display: flex;
  align-items: center;
  border: 1px solid #34a853;
  background-color: #34a853;
  color: #ffff;
  border-radius: 5px;
  padding: 0.4rem 2rem;
  cursor: pointer;
}

.shop_cart_buttons {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  padding: 0.3rem;
  border-radius: 5px;
}
.decrement {
  border: none;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  color: #2e2d2d;
  cursor: pointer;
}

.increment {
  border: none;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  color: #2e2d2d;
  cursor: pointer;
}
/* cart styles */

.cart_items_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart_items_remove_button {
  padding: 0.3rem 0.5rem;
  background-color: #ea4335;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  border: 2px solid #e64646;
  border-radius: 10px;
  cursor: pointer;
}

.cart_items_remove_button:hover {
  background-color: #cc352d;
  color: #ffffff;
  transition: background-color 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cart_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cart_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #ffffff;
  width: 100%;
  max-width: 900px;
  overflow: auto;
  padding: 0.5rem;
  border: 2px solid #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cart_address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid #e6e6e6;
}

.address_boxis {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.address_boxis h4 {
  margin: 0px;
  font-size: 1.1rem;
  color: #2e2d2d;
  font-weight: 500;
}

.address_edit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage_address {
  display: flex;
  align-items: center;
  border: none;
  background-color: #4285f4;
  border: 2px solid #4285f4;
  border-radius: 35px;
  padding: 0.4rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}
.manage_address:hover {
  background-color: #175cca;
  color: #ffffff;
  transition: background-color 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.emptytext {
  font-size: 0.9rem;
  color: #707979;
  font-weight: 400;
  margin: 0px;
}

.cart_main_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.cart_items_count {
  font-size: 1.1rem;
  color: #2e2d2d;
  font-weight: 500;
  margin: 0px;
}
.cart_product-wrapper {
  display: grid;
  gap: 0.5rem;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 1rem;
}

.cart_p_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.cart_product {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* align-items: baseline; */
}

.cartr_p_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 100px;
  margin: 0px;
}

.cart_p_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.cart_p_info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.cart_P_name {
  font-size: 1rem;
  font-weight: 600;
  width: 200px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cart_P_categorys {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}
.cart_P_qty {
  font-size: 0.9rem;
  font-size: 500;
  color: #7a7979;
  margin: 0px;
}
.Product-Q-weight {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.weight {
  font-size: 0.9rem;
  margin: 0px;
  color: #474646;
}

.cart_P_price {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  font-size: 0.9rem;
  margin: 0px;
}
.cart__price_text {
  font-size: 0.9rem;
  margin: 0px;
  color: #fbbc05;
}
.cart_P_unit {
  font-size: 0.9rem;
  margin: 0px;
  color: #2a2d2d;
}

.cart_prices_wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.agentpricesbar {
  display: flex;
  align-items: baseline;
  justify-content: baseline;
  gap: 1rem;
}

.cart_prices_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
  justify-content: right;
}
.cart_prices {
  display: grid;
  gap: 0.5rem;
}

.prices {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.productPrice {
  font-size: 1rem;
  margin: 0px;
  color: #2a2d2d;
}

.price_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #7a7979;
}
.price_head {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  color: #2a2d2d;
}
.bagpricetag {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  color: #fbbc05;
}
.pricetag {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #fbbc05;
}

.Bags_Container {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.bagsPrice {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: first baseline;
}

.bagsPrices {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.totalPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.pricebox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.price1 {
  font-size: 1rem;
  font-weight: 500;
  color: #7a7979;
  text-decoration: line-through 1px solid #474646;
  margin: 0px;
}

.TransportContainer {
  display: grid;
  gap: 1rem;
}

.transport_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  gap: 1rem;
}

.transport_header h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
  font-family: "Muli";
}
.transportEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #4285f4;
  outline: none;
  border-style: none;
  cursor: pointer;
}
.transport_Info {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  /* cursor: pointer; */
}
.transport_Info p {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

#order_gst {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  color: #7a7979;
  font-size: 0.8rem;
}

#order_discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  color: #34a853;
  font-size: 0.8rem;
}

.cart_btn_conatiner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.cart_delete_btn {
  display: flex;
  align-items: center;
  justify-content: right;
}

.cart_p_d {
  border: none;
  font-size: 1rem;
  margin: 0px;
  font-weight: 500;
  color: #ea4335;
  background-color: #ffffff;
  border: 2px solid #ea4335;
  cursor: pointer;
  border-radius: 5px;
}
.Cart_w_prices {
  display: grid;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #eee;
}

.cart_t_bill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.t_bill_text {
  font-size: 1.1rem;
  margin: 0px;
  color: #2e2d2d;
  font-weight: 400;
}

.t_bill_price {
  display: flex;
  font-size: 1.1rem;
  margin: 0px;
  color: #fbbc05;
  font-weight: 400;
}

.place_order_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.cart_order_btn {
  font-size: 1rem;
  border: 1px solid #34a853;
  background-color: #34a853;
  color: #ffff;
  border-radius: 34px;
  padding: 0.5rem 5rem;
}

.address_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.address_container_2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  max-height: 75vh;
  overflow: scroll;
  gap: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #ffff;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.address_container_3 {
  display: flex;
  flex-direction: column;
  /* position: relative; */
}

.address_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
}

.check_address {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address_input {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 0px;
}

.adress_boxeses {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.a_address {
  align-items: center;
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}

.a_address_2 {
  align-items: center;
  font-size: 1rem;
  margin: 0px;
  color: #2a2d2d;
}

.address_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
}

.a_edit {
  border: none;
  background-color: transparent;
  color: #fbbc05;
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
}
.a_edit:hover {
  color: #ffff;
  background-color: #e0c164;
  padding: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.r_edit {
  border: none;
  background-color: transparent;
  color: #e76c60;
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
}
.r_edit:hover {
  color: #ffff;
  background-color: #ee5749;
  padding: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.addres_add_container {
  position: absolute;
  bottom: 5px;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  /* padding: 0.5rem; */
}

.addreess_add_button {
  border: 2px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 34px;
  padding: 0.5rem 4rem;
  cursor: pointer;
}

.addreess_add_button:hover {
  background-color: #1b59bb;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.addreess_add_button:disabled {
  background-color: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

/* modal content */
.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5rem;
  width: 100%;
  max-width: 330px;
  height: 400px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

/* Agent styles */
.agent_boxes_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.user_image_container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0.5rem;
}
.group_user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.group_user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}

.group_user_info {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: center;
}

.group_user_name {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #4285f4;
}

#group_user_number {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

#group_user_type {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

/* Product Details  styles*/
.user_d_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user_d_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_d_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#user_d_header_h1 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
}

.product_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* height: 85vh; */
}

.product_container_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  gap: 1rem;
  border-radius: 3px;
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
}

.product_container_header {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  height: 30vh;
}

.prod_image_container {
  margin: 0 auto;
  width: 100%;
  min-height: 100px;
}

.prod_image {
  position: relative;
  top: 0%;
  margin: 0px;
  height: 20vh;
  background-color: #def5ff;
}

.prod_img {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 120px;
  object-fit: cover;
  z-index: 1;
}
.product_info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

#product_text_1 {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}

.product_qty {
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}

#product_text_3 {
  font-size: 0.9rem;
  background-color: #34a853;
  color: #ffff;
  border: 1.2px solid #34a853;
  padding: 0.3rem 2rem;
  border-radius: 32px;
  margin: 0px;
}

#product_price_box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fbbc05;
  margin: 0px;
}

#product_gst {
  font-size: 1rem;
  margin: 0px;
  color: #34a853;
}

#product_text_4 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  gap: 10px;
  margin: 0px;
}

.prod_details_logo {
  width: 50px;
  height: 50px;
  flex: 1;
  align-content: left;
  justify-content: center;
  margin: 0;
}

#prod_details_text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  /* text-align: left; */
  align-items: left;
  justify-content: center;
}

#prod_mesure_p {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

#prod_units {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}
.prod_btn_conatiner {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.prod_action_edit {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 4rem;
  background-color: #34a853;
  border: 1.2px solid #34a853;
  color: #ffff;
  border-radius: 35px;
  cursor: pointer;
}

/* PLace Order form   styles*/

.form_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.form_wrapper form {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 850px;
  /* border: 2px solid #ffffff; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* padding: 1rem; */
  /* border-radius: 0.6rem; */
}

.form-control {
  display: grid;
  width: 100%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
}
.agentPriceEdit {
  background-color: #ffffff;
  border: 2px solid #34a853;
  color: #34a853;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
}

.dropdown {
  position: absolute;
  right: 10px;
  top: 20px;
}

.dropdwons {
  font-weight: 400;
  line-height: 1.4;
  color: #474646;
  text-indent: 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

/* .input-control {
  display: grid;
  gap: 1rem;
  width: 28%;
  align-items: baseline;
  justify-content: baseline;
  position: relative;
} */

.input-control select {
  position: relative;
  display: block;
  /* width: 85%; */
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  /* text-indent: 1.2rem; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
.input-control select:not(:focus) {
  background-color: #f1f6fb;
}

.input-control select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

label {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
}

.form-control input,
.form-control textarea {
  position: relative;
  display: block;
  width: 95%;
  height: 1.8rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control select {
  position: relative;
  display: block;
  width: 98%;
  /* padding: 0.375rem 0.75rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #474646;
  text-indent: 1.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control input:not(:focus) {
  background-color: #f1f6fb;
}

.form-control input:disabled {
  background-color: lightgray;
}
.form-control select:disabled {
  background-color: lightgray;
}

.form-control textarea:not(:focus) {
  background-color: #f1f6fb;
}

.form-control select:not(:focus) {
  background-color: #f1f6fb;
}

.form-control input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.form-control select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.form-control span {
  margin-top: 0.5rem;
  color: #e64646;
  font-size: 0.9rem;
  padding: none;
}
.Addtarnsportbtn {
  display: grid;
  align-items: center;
  justify-content: center;
}
.addTransport {
  font-size: 1rem;
  padding: 0.5rem 2rem;
  background-color: #4285f4;
  border: 1.2px solid #4285f4;
  color: #ffff;
  border-radius: 5px;
  cursor: pointer;
}

.addTransport:hover {
  background-color: #4285f4;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Media query for screens with a maximum width of 480px (typical smartphones) */
@media screen and (max-width: 580px) {
  .manufacturer_banner_image {
    height: 150px;
  }

  .manufacturer_products_container {
    top: 0;
    margin-top: 0;
  }

  .manufacturer_product_items {
    width: 100%;
  }

  .m_products_header {
    flex-direction: column;
    position: sticky;
    z-index: 1;
    background-color: #ffff;
  }

  .m_products_header_items,
  .m_products_header_items_quantity,
  .m_products_header_cart,
  .m_products_header_cart_items,
  .m_products_header_cart_button {
    width: 100%;
  }

  .add_remove_buttons,
  .add_remove_buttons {
    flex-direction: column;
    align-items: center;
  }

  .add_button,
  .remove_button {
    width: 100%;
    margin-top: 10px;
  }
  .m_products_header_cart {
    flex-direction: column;
    padding: 0px;
  }
}
/*Order Successfull*/

.Success_card {
  border: 1px solid #ffffff;
  width: 20rem;
  gap: 10px;
  text-align: center;
  padding: 80px 40px;
  background-color: #ffffff;
}

.Success_card_buttons {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  list-style-type: none;
}

.Success-circle-container {
  font-size: 60px;
  color: #34a853;
}

.Success-Header {
  color: #34a853;
}
.Success_card_button_one {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.Success_card_button_one:hover {
  color: #34a853;
}

.Success_card_container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Query for Tablet */
@media (max-width: 768px) {
  .Success_card {
    width: 100%;
    height: 350px;
    text-align: center;
    padding: 40px 20px;
  }

  .Success_card_buttons {
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Success_card_buttons {
    gap: 10px;
  }
}

/* Responsive styles */
@media (max-width: 600px) {
  .shopping-cart {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .home_wrapper {
    width: calc(100% - 32px);
    position: relative;
  }
}

/* address page */
.adrdress_from_container {
  margin-top: 20px;
}

.adrdress_from_container form {
  width: 100%;
}

/* .adrdress_from_container form div {

} */

.adrdress_from_container form input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
.shop_btns_a {
  display: flex !important;
  gap: 0.5rem;
  padding: 1rem 0;
  place-content: center;
}
.adrdress_from_container form button[type="submit"] {
  padding: 10px 20px;
  background-color: #ea4335;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.adrdress_from_container form button[type="submit"]:hover {
  background-color: #b22417;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.adrdress_from_container form div {
  display: grid;
  gap: 0.5rem;
}
.adrdress_from_container form div p {
  font-size: 0.9rem;
  color: #ea4335;
  margin: 0px;
  padding-left: 5px;
}
.adrdress_from_container form button[type="button"] {
  border: 1px solid #ccc;
  width: 5rem;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.adrdress_from_container form button[type="button"]:hover {
  background-color: #cccccca4;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.errors {
  color: #ea4335;
  font-size: 14px;
}

/*groups */

.home_groups {
  margin-bottom: 10px;
}

.home_groups_name_button {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.home_group_name {
  font-size: 1.1rem;
  text-transform: initial;
  margin: 0px;
  font-weight: 500;
}

.home_group_button_view {
  background-color: transparent;
  color: #4285f4;
  font-size: 0.9rem;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
}

.home_single_groups {
  margin: 10px;
}
