.home_wrapper {
  position: relative;
  background-color: #ffffff;
  /* min-height: calc(100vh - 182px); */
  padding: 1rem 1.5rem;
  margin-top: 3rem;
}
/*  permission */

.permission_container {
  width: 100%;
  height: 90vh;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.permission_container h2 {
  font-size: 1.1rem;
  font-family: "Muli";
  margin-left: 0.5rem;
  width: 100%;
  max-width: 300px;
}

.permission_container img {
  width: 100%;
  max-width: 500px;
}

/* empty handle */

.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25rem;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #7a7979;
}

.empty_cell {
  display: grid;
  justify-items: center;
}
/*  */

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.Order_container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid #fafafa; */
  background-color: #ffffff;
  /* box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  padding: 20px;
}

.order_status_select {
  display: none;
}

.Order_statusbtn {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: flex-start; /* Align items to the start */
  gap: 1rem;
  width: auto; /* Allows the container to adjust width dynamically */
  max-width: 100%; /* Prevents the container from exceeding the width of its parent */
  overflow-x: auto; /* Enables horizontal scrolling if needed */
  white-space: nowrap; /* Prevents wrapping of child elements */
}

.order_statusbuttons {
  color: #2e2d2d;
  border-style: none;
  background-color: #ffff;
  cursor: pointer;
  font-size: 1rem;
  min-height: 30px;
}

.order_statusbuttons:hover {
  border-color: 3px solid #4285f4;
}

.order_statusbuttons.active {
  color: #4285f4;
  border-bottom: 3px solid #4285f4;
}

.Order_table_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 68vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Order_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.Order_table th {
  padding: 0.6rem;
  background-color: #4285f4;
  color: #fff;
  width: 150px;
  font-size: 1rem;
  font-weight: 400;
}

.highlight-row td {
  background-color: #fd0e35;
  color: #ffffff;
}
.Order_table td {
  padding: 0.9rem;
}

.Order_table td {
  /* color: #212121; */
  font-size: 0.9rem;
  cursor: pointer;
}

.Order_table tr {
  border: 1px solid #ddd;
}

.orderbyId_btn {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 0.9rem;
}

.send_remainder {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
}

.view_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 10px;
}

.page-item {
  list-style: none;
  padding: 2px 8px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#view_page_count {
  font-size: 0.7rem;
}

#view_page_count .number {
  font-weight: 600;
  font-size: 0.7rem;
  color: #4285f4;
}

.order_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem 0; */
  margin: 0px;
}

.reorder_button {
  border: 1px solid #34a853;
  color: #ffff;
  background-color: #34a853;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}

/* Loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Model   styles */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlaye {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.update_modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 280px;
  height: 300px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  /* align-items: center; */
  justify-content: center;
  text-align: left;
}

#resct_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}
.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.banner_input {
  display: flex;
  align-items: center;
}
/* Agent Addess Modal  */
.reject_input_modal {
  width: 100%;
  height: 100px;
  align-items: center;
  text-indent: 1rem;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.address_Modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  text-align: left;
}

.address_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.add_header_h2 {
  font-size: 1.2rem;
  color: #2e2d2d;
  margin: 0px;
  text-align: center;
}

.address_container_2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  max-height: 75vh;
  overflow: scroll;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.address_container_3 {
  display: flex;
  flex-direction: column;
  /* position: relative; */
}

.address_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
}

.check_address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agent_boxes_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.user_image_container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0.5rem;
}
.group_user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.group_user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}

.address_input {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 0px;
}

.adress_boxeses {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.a_address {
  align-items: center;
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}

.a_address_2 {
  align-items: center;
  font-size: 1rem;
  margin: 0px;
  color: #2a2d2d;
}

.address_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
}

.a_edit {
  border: none;
  background-color: transparent;
  color: #fbbc05;
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
}
.a_edit:hover {
  color: #ffff;
  background-color: #e0c164;
  padding: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.r_edit {
  border: none;
  background-color: transparent;
  color: #e76c60;
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
}
.r_edit:hover {
  color: #ffff;
  background-color: #ee5749;
  padding: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.addres_add_container {
  position: absolute;
  bottom: 5px;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  /* padding: 0.5rem; */
}

.addreess_add_button {
  border: 2px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 34px;
  padding: 0.5rem 4rem;
  cursor: pointer;
}

.addreess_add_button:hover {
  background-color: #1b59bb;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.addreess_add_button:disabled {
  background-color: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

.modal-content-header {
  padding: 1rem;
  font-weight: 500;
  margin: 0px;
  background-color: #f9fafb;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}
.modal-content-header h1 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  margin: 0px;
  color: #2e2d2d;
}
.close-btn {
  align-self: center;
  background-color: white;
  border: 1px solid white;
  outline: none;
  color: red !important;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.group_user_name {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #4285f4;
}

#group_user_number {
  text-align: left;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

#group_user_type {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

/* address page */
.adrdress_from_container {
  margin-top: 20px;
}

.adrdress_from_container form {
  width: 100%;
}
.Address_form {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.adrdress_from_container form input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
.shop_btns_a {
  display: flex !important;
  gap: 0.5rem;
  padding: 1rem 0;
  place-content: center;
}
.adrdress_from_container form button[type="submit"] {
  padding: 10px 20px;
  background-color: #ea4335;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.adrdress_from_container form button[type="submit"]:hover {
  background-color: #b22417;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.adrdress_from_container form div {
  display: grid;
  gap: 0.5rem;
}
.adrdress_from_container form div p {
  font-size: 0.9rem;
  color: #ea4335;
  margin: 0px;
  padding-left: 5px;
}
.adrdress_from_container form button[type="button"] {
  border: 1px solid #ccc;
  width: 5rem;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.adrdress_from_container form button[type="button"]:hover {
  background-color: #cccccca4;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.errors {
  color: #ea4335;
  font-size: 14px;
}

@media screen and(min-width: 650px) {
  .home_wrapper {
    width: calc(100% - 32px);
  }
  .order_status_select {
    display: flex;
    float: right;
    align-content: end;
    justify-self: end;
  }
}
