.home_wrapper {
  position: relative;
  background-color: #ffffff;
  /* min-height: calc(100vh - 182px); */
  padding: 1rem 1.5rem;
  margin-top: 3rem;
}

.home_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Add_main_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_back_btn {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}

.manageprod_search_container {
  display: grid;
  grid-template-columns: 75% 23%;
  gap: 2rem;
}

.manageprod_search {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #d5d6d7;
  border-radius: 35px;
  padding: 0.6rem 0.5rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.manage_prod_search {
  width: 20px;
  height: 20px;
  font-size: 1rem;
  /* margin-right: 0.5rem;  */
}

.manageprod_search input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
  font-size: 1rem;
}
/* loader */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.actives {
  border-bottom: 2px solid #4285f4 !important ;
}

.Order_container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0 1rem;
}

.Order_statusbtn {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  /* border-bottom: 1px solid #ccc; */
}

.order_statusbuttons {
  background-color: #ffff;
  color: #7a7979;
  background-color: #fff;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 1rem;
  min-height: 46px;
  padding: 0px;
}

.order_statusbuttons:hover {
  border-bottom: 2px solid #4285f4;
}

.order_statusbuttons.active {
  background-color: #ffff;
  color: #4285f4;
  border-bottom: 3px solid #4285f4;
}

.Order_table_container {
  width: 100%;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 55vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Order_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}
.Order_table th {
  padding: 0.6rem;
  background-color: #4285f4;
  color: #fff;
  width: 150px;
  font-weight: 100;
  font-size: 1rem;
}

.Order_table td {
  padding: 0.9rem;
}

.Order_table td {
  color: #7a7979;
  font-size: 0.9rem;
  cursor: pointer;
}

.Order_table tr {
  border: 1px solid #ddd;
}

.pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.pagination {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 5px;
}

.page-item {
  list-style: none;
  padding: 2px 8px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
