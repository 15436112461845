.Container {
  background-color: #edf4ff;
  height: calc(100% - 3rem);
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  position: fixed;
  width: 230px;
  left: 0;
  z-index: 15;
  padding: 0 0.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0.5rem;
  user-select: none;
  transition: width 0.3s ease;
  margin-top: 2.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.Container a {
  user-select: none;
}
.Container::-webkit-scrollbar {
  display: none;
}
.seo {
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
}
.seo {
  display: grid;
  gap: 0.5rem;
  /* overflow: scroll; */
}
.seo-item {
  display: flex;
  font-size: 0.9rem;
  cursor: pointer;
  gap: 1rem;
  color: white;
  padding: 0 0.8rem;
}

.seo-item-nav {
  display: grid;
  gap: 0.3rem;
  overflow: hidden;
}

.seo-item p {
  color: white;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  font-weight: 500;
  font-size: 1.05rem;
  font-family: "Open Sans", sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                 white-space: nowrap; /* Prevent the paragraph from wrapping to the next line */
}
.cancel {
  background-color: red;
  color: white;
}
.hide {
  width: 70px;
}

.hamburger {
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  justify-items: center;
  align-items: center;
}
.hamburger > img {
  cursor: pointer;
  width: 100%;
  max-width: 3rem;
  margin-left: 3rem;
  align-self: center;
  transition: opacity 0.2s ease;
  flex-grow: 2;
  justify-self: center;
  text-align: center;
}
.hide .hamburger {
  grid-template-columns: 1fr;
}
.hide .hamburger img {
  display: none;
}
.hamburger-icon {
  cursor: pointer;
  border: none;
  padding: 0 !important;
  outline: none;
  color: #2a2d2d;
}
.hamburger-icon svg {
  color: #2a2d2d !important;
}
.hide .hamburger-icon {
  right: 10px;
}
.userdata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.navlinks {
  display: grid;
  gap: 0.3rem;
  margin-top: 0.8rem;
}

.icon_wrapper {
  position: relative;
}

.icon_count {
  position: absolute;
  top: -3px;
  left: 10px;
  font-size: 0.8rem;
  color: white !important;
  width: 1.2rem;
  text-align: center;
  border-radius: 50%;
  height: 1.1rem;
  background-color: #ea4335;
}
.nav-icon {
  font-size: 1.4rem;
  color: inherit;
}
.navlinks a,
.Container button {
  align-items: center;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #2a2d2d;
  text-wrap: nowrap;
  overflow: hidden;
  gap: 1rem;
  text-decoration: none;
  user-select: none;
  outline: none;
  cursor: pointer;
}
.navlinks a {
  padding: 0.3rem 0.8rem;
}
.navlinks a:hover {
  color: #ffffff;
  background-color: #4285f4;
  border-radius: 10px;
}
.seo-item-nav a {
  padding: 0.5rem;
}
.navlinks a span {
  /* margin-top: 0.4rem; */
  overflow: hidden;
}

.sub-link-hide {
  padding-left: 0 !important;
  border: none !important;
}

.navlinks a img,
.Container button img {
  width: 1.1rem;
}
.hide .navlinks a {
  display: flex;
  place-content: center;
}
.navActive {
  color: #ffff !important;
  background-color: #4285f4 !important;
  border-radius: 10px;
}
.navInactive {
  color: #000000;
}
.Container button {
  border: none;
  background-color: inherit;
  color: #2a2d2d !important;
  cursor: pointer;
  padding: 0.7rem 1rem;
}
.Container button img {
  padding: 2rem 0 0 0;
}
/* .logout {
    margin-top: 1rem;
} */
.hidden {
  opacity: 0;
}
.hide .logout {
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide button image {
  padding: 0;
}
/* .hide .Container button p {
    padding: 0;
} */
.img-size {
  width: 3rem !important;
}
.hide .navlinks {
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}
.hide .navlinks a,
.hide .seo-item {
  background-color: transparent;
  transition: background-size 0.2s;
  background-image: linear-gradient(to right, white, #4285f4);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.hide .seo-item {
  padding: 0.8rem 0;
  display: flex;
  place-content: center;
  user-select: none;
  outline: none;
  border: none;
}
.hide .navlinks a:hover,
.hide .seo-item:hover {
  color: #4285f4;
  background-size: 100% 100%;
}
.seo-item-nav a {
  padding-left: 3rem;
}

.logbutton {
  margin: 0px;
}

/* user data */

.userdata {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
}
.userBname {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Marcellus SC";
  align-items: center;
  text-align: center;
  width: 210px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px;
  color: #2e2d2d;
}
.userName {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Marcellus SC";
  margin: 0px;
  color: #2e2d2d;
}

/* Toggle Button  Styles*/
.user_active_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  margin-top: 0.5rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 32px;
  margin: 0px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ea4335;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: #ffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #34a853;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4285f4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "Ofline";
  color: #ffff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
  right: 50px;
}

input:checked + .slider:after {
  content: "Online";
  position: relative;
  left: 60px;
}

@media screen and (max-width: 900px) {
  .Container {
    height: calc(100% - 3.5rem);
    overflow: scroll;
  }
  .backdrop {
    user-select: none;
    position: fixed;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);

    z-index: 10;
  }
  .Container {
    gap: 1rem;
    /* width: 70px; */
  }
  /* .seo-item p {
        display: none;
    } */
}
