body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f4fc; */
}

::-webkit-scrollbar {
  display: none;
}

* {
  font-family: "Muli";
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Date  input  */

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 10px;
  color: #4285f4 !important;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 0px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 0px;
}
