.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.model_image {
  position: relative;
  width: 100%;
  /* max-width: 800px; */
}

.model_image .Tanyya_image {
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 1;
}

.hero_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(66, 133, 244, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
}

.model_content {
  padding: 0 20px;
}

.login_maindiv {
  display: flex;
  width: 100%;
  max-width: 800px;
  height: 100vh;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  scroll-behavior: unset;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.logoText {
  width: 100%;
  max-width: 300px;
  height: auto;
  /* margin-bottom: 30px; */
}

.login_div {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  display: inline-grid;
}
.login_h1 {
  font-size: 1.5rem;
  margin: 0px;
  padding: 10px 0;
  align-items: center;
}

.login_box_div {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  display: inline-grid;
  gap: 1rem;
}

.login_text_container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
}
.subscription_text {
  font-size: 1.4rem;
  margin: 0px;
}

.subscription_plan {
  font-size: 1.6rem;
  margin: 0px;
  color: #20a0ff;
}

/* Conformation styles */
.conform_ammount {
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #2a2d2d;
  margin: 0px;
}

.confirm_todate {
  font-size: 1rem;
  font-weight: 600;
  color: #7a7979;
  margin: 0px;
}

.bill_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 2px solid #e6e6e6;
  padding: 0.5rem;
}

.bill_cycle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bill_cycle_header {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2a2d2d;
  margin: 0px;
}

.bill_cycle_text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2a2d2d;
  margin: 0px;
}

.total_bill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.total_bill_header {
  color: #20a0ff;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
}

.total_bill_headers {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #7a7979;
}

.total_bill_text {
  color: #20a0ff;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  align-items: center;
  display: flex;
}

.total_bill_texts {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  align-items: center;
  display: flex;
  color: #7a7979;
}

.ruppee_icon {
  margin: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
}

.confirm_button {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  max-width: 500px;
  align-items: center;
  /* text-align: center;
  justify-content: center; */
}
.empty-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 500px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.emptytext {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.emptytext p {
  color: #2e2d2d;
  margin: 0px;
  font-size: 1.2rem;
  font-weight: 500;
}

.sub_btn {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  margin: 0px;
  background-color: #20a0ff;
  color: #ffff;
  border: 2px solid #20a0ff;
  border-radius: 5px;
  font-family: "Muli";
  cursor: pointer;
}

.sub_ctn {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  margin: 0px;
  background-color: #ffff;
  color: #fc0d0e;
  border: 2px solid #fc0d0e;
  border-radius: 5px;
  font-family: "Muli";
  cursor: pointer;
}

/* checking */
.confirm_check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check_mark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #98ffb3;
}
.check_container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 50%;
  background-color: #34a853;
}
.tick_mark {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #ffff;
}

/* model  styles */

.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  height: 200px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.user-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  height: 250px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
}

#delete_header {
  color: #7a7979;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0px;
}

#delete_text1 {
  font-weight: 700;
  font-family: "Kumbh Sans-Medium", Helvetica;
  font-size: 1.3rem;
  margin: 0px;
  padding: 5px;
  color: #2e2d2d;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#delete_conform_text {
  font-size: 1.1rem;
  color: #797979;
  padding: 10px 0;
  margin: 0px;
  text-align: center;
}
.delete_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0px;
}

.deactivate_model_btn {
  border: none;
  color: #2a2d2d;
  background-color: #ffff;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.deactivate_btn {
  border: 1px solid #ea4335;
  color: #ffff;
  background-color: #ea4335;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.calcel_back_btn {
  border: none;
  color: #2a2d2d;
  background-color: transparent;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.renew_model_btn {
  border: 1px solid #34a853;
  color: #ffff;
  background-color: #34a853;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.close_modal {
  position: absolute;
  background-color: #ea4335;
  color: #aaa;
  top: 0px;
  right: 0%;
  padding: 2px 4px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.home_wrapper.blur {
  filter: blur(5px);
}

@media (max-width: 920px) {
  .login {
    grid-template-columns: 1fr 1fr;
  }
  .model_image .Tanyya_image {
    width: 100%;
  }
  .hero_mask {
    width: 100%;
  }

  .model_content {
    padding: 0 10px;
  }

  .login_div {
    max-width: 350px;
  }
}

/* For screens with a maximum width of 720px */
@media (max-width: 720px) {
  .login {
    grid-template-columns: 1fr;
  }
  .model_image .Tanyya_image {
    display: none;
  }
  .hero_mask {
    display: none;
  }

  .login_div {
    width: 100%;
    /* max-width: 400px; */
  }

  .logoText {
    max-width: 300px;
    /* margin-bottom: 20px; */
  }
  .otp-input {
    width: 100px;
    /* height: 25px; */
    font-size: 1rem;
  }
  .otp-separator {
    margin: 0 8px;
    font-size: 14px;
  }
  button {
    font-size: 1.2rem;
  }

  .login_box_div {
    align-items: center;
    width: 100%;
    max-width: 400px;
    /* padding: 15px; */
    display: inline-grid;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .login_div {
    max-width: 100%;
    max-width: 270px;
    padding: 0 10px;
  }

  .login_input {
    height: 20px;
    width: 250px;
    font-size: 1rem;
    padding: 10px;
  }
  .otp-input-container {
    display: flex;
    justify-content: flex-start;
    /* padding: 5px; */
    align-items: center;
  }
  .otp-input {
    width: 100px;
    height: 20px;
    font-size: 1rem;
    padding: 5px 20px;
  }
  .otp-separator {
    margin: 0 5px;
    font-size: 10px;
  }
  .S_btn {
    width: 100%;
    font-size: 1rem;
  }
  .login_box_div {
    max-width: 350px;
  }
}
