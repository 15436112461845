.Navigator {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  width: auto;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.Navigator button {
  /* min-height: 46px; */
  padding: 10px;
  border: 3px solid transparent;
  font-family: "Muli";
  font-size: 1.1rem;
  font-weight: 400;
  background-color: #ffffff;
  cursor: pointer;
  color: #2a2d2d;
}
.Navigator button:hover {
  border-bottom: 3px solid #4285f4;
}
.Navigator button span {
  margin-left: 4px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.active {
  color: #4285f4;
  border-bottom: 3px solid #4285f4 !important;
}
