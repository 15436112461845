.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.model_image {
  position: relative;
  width: 100%;
  /* max-width: 800px; */
}

.model_image .Tanyya_image {
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 1;
}

.hero_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #4285f4b3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
}

.model_content {
  padding: 0 20px;
}

.login_maindiv {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  scroll-behavior: unset;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.logoText {
  width: 100%;
  max-width: 300px;
  height: auto;
  /* margin-bottom: 30px; */
}

.login_div {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  display: inline-grid;
}
.login_h1 {
  font-size: 1.5rem;
  margin: 0px;
  padding: 10px 0;
  align-items: center;
}
.login_div form {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

label {
  font-weight: 500;
  margin-bottom: 8px;
}

.login_input {
  height: 25px;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-indent: 10px;
  outline: none;
}
.login_input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.error {
  margin: 0;
  color: #f98080;
  font-size: 1rem;
  font-weight: 400;
}
.resetpassword {
  display: flex;
  justify-content: right;
  text-align: right;
  margin: 0px;
  color: #20a0ff;
  padding: 5px 0;
}

.resetbtn {
  text-align: right;
  border-style: none;
  font-size: 1rem;
  font-weight: 500;
  color: #4285f4;
  cursor: pointer;
  margin: 0px;
  background-color: #ffff;
}

.formcontainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.formcontainer input {
  width: 500px;
  border-radius: 4px;
  text-indent: 10px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding: 10px;
}
.formcontainer p {
  position: relative;
  margin: 0;
  color: red;
  font-size: 14px;
  right: 0px;
  align-self: flex-end;
  margin-top: -10px;
}

.password-input {
  position: relative;
}

.password-input svg {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  color: #4285f4b3;
}

.backscreen {
  display: flex;
  align-items: left;
  justify-content: left;
  position: absolute;
  top: 5px;
  left: 5px;
}

.Add_back_btn {
  border-style: none;
  background-color: #ffff;
  color: #20a0ff;
  padding: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  margin: 0px;
  cursor: pointer;
}

/* .......CheckBox......... */

.checkbox-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.checkbox-container input {
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-right: 10px;
}

.password-input-container {
  position: relative;
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.input-with-icon input {
  flex: 1;
}

.input-with-icon svg {
  position: absolute;
  top: 25%;
  right: 10px;
  cursor: pointer;
  color: #20a0ff;
}

.checkbox_text {
  font-size: 0.8rem;
  color: #2a2d2d;
  font-weight: 500;
  margin-bottom: 5px;
}

/* Style for the container of the OTP input */

.otp-input-container {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  /* padding: 10px; */
  /* align-items: center; */
}

.otp-input {
  width: 100px;
  height: 25px;
  font-size: 1.2rem;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 10px 30px;
  margin: 5px 5px;
  text-align: center;
  justify-content: center;
}

.otp-separator {
  margin: 0 10px;
  font-size: 16px;
}

.S_btn {
  padding: 10px;
  background-color: #4285f4;
  color: #fff;
  border: none;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.S_btn:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.or {
  text-align: center;
  margin: 0.6rem 0;
}

.type {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #7a7979;
  font-size: 1rem;
  padding: 10px 0;
  text-decoration-line: underline;
  cursor: pointer;
}

.signup {
  text-align: center;
  margin-top: 16px;
}

/* Subscription   Styles    */

.login_box_div {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  display: inline-grid;
  gap: 1rem;
}

.login_text_container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
}
.subscription_text {
  font-size: 1.4rem;
  margin: 0px;
}

.subscription_plan {
  font-size: 1.6rem;
  margin: 0px;
  color: #20a0ff;
}
.description {
  font-size: 1rem;
  color: #2e2d2d;
  margin: 0px;
}

.plans_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.plan_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 0.3rem;
}
.money_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_money_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0px;
  padding: 1rem;
}

.plan_details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.5rem;
  background-color: #f1f6fb;
  border-radius: 5px;
}

.money_text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  font-size: 1.5rem;
  font-weight: 600;
}

.money_month_text {
  margin: 0px;
  align-items: center;
  justify-content: center;
}

.plan_text1 {
  display: flex;
  align-items: left;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #20a0ff;
}

.plan_text2 {
  font-size: 0.8rem;
  margin: 0px;
  color: #7a7979;
}

.plan_text2 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  margin: 0px;
  align-items: center;
}
.plan_text2_span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7979;
  margin: 0px;
  font-size: 0.8rem;
}

.plan_text2_span2 {
  align-items: center;
  justify-content: center;
  color: #7a7979;
  margin: 0px;
  font-size: 0.8rem;
}

.subscribe_plan_btn {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.sub_btn {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  margin: 0px;
  background-color: #20a0ff;
  color: #ffff;
  border: 2px solid #20a0ff;
  border-radius: 5px;
}
.sub_ctn {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  margin: 0px;
  background-color: #ffff;
  color: #fc0d0e;
  border: 2px solid #fc0d0e;
  border-radius: 5px;
}

@media (max-width: 920px) {
  .login {
    grid-template-columns: 1fr 1fr;
  }
  .model_image .Tanyya_image {
    width: 100%;
  }
  .hero_mask {
    width: 100%;
  }

  .model_content {
    padding: 0 10px;
  }

  .login_div {
    max-width: 350px;
  }
}

/* For screens with a maximum width of 720px */
@media (max-width: 720px) {
  .login {
    grid-template-columns: 1fr;
  }
  .model_image .Tanyya_image {
    display: none;
  }
  .hero_mask {
    display: none;
  }

  .login_div {
    width: 100%;
    /* max-width: 400px; */
  }

  .logoText {
    max-width: 300px;
    /* margin-bottom: 20px; */
  }
  .otp-input {
    width: 100px;
    /* height: 25px; */
    font-size: 1rem;
  }
  .otp-separator {
    margin: 0 8px;
    font-size: 14px;
  }
  button {
    font-size: 1.2rem;
  }

  .login_box_div {
    align-items: center;
    width: 100%;
    max-width: 400px;
    /* padding: 15px; */
    display: inline-grid;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .login_div {
    max-width: 100%;
    max-width: 270px;
    padding: 0 10px;
  }

  .login_input {
    height: 20px;
    width: 250px;
    font-size: 1rem;
    padding: 10px;
  }
  .otp-input-container {
    display: flex;
    justify-content: flex-start;
    /* padding: 5px; */
    align-items: center;
  }
  .otp-input {
    width: 100px;
    height: 20px;
    font-size: 1rem;
    padding: 5px 20px;
  }
  .otp-separator {
    margin: 0 5px;
    font-size: 10px;
  }
  .S_btn {
    width: 100%;
    font-size: 1rem;
  }
  .login_box_div {
    max-width: 350px;
  }
}
