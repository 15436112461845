.ReactModal__Overlay {
  position: fixed;
  top: 0;
  inset: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.ReactModal__Content {
  position: relative;
  background-color: #ffff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.close-button:hover {
  color: #000;
}

.model_quantity_input {
  margin-top: 10px;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
}
.model_quantity_input:not(:focus),
.model_quantity_select:not(:focus) {
  background-color: #f1f6fb;
}

.model_quantity_input:focus,
.model_quantity_select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.model_quantity_select {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
}

.model_button {
  width: 50%;
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: white;
  cursor: pointer;
}

.Model_buttons {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.model-left-button {
  margin-right: auto;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #34a853;
  color: #fff;
  border: none;
  cursor: pointer;
}
.model-left-button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
.model-right-button {
  margin-left: auto;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ea4335;
  color: #ffff;
  border: none;
  cursor: pointer;
}

.errorText {
  font-size: 0.9rem;
  margin: 0px;
  color: #ea4335;
}
