/* Reusable modal overlay styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content container */
.modalContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 300px;
}

/* Modal header with title and close button */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalHeader h2 {
  flex: 1;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.3rem;
  background: red;
  border: 1px solid red;
  color: #ffffff;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
  border-radius: 36px;
}

.modalBody {
  margin-bottom: 20px;
}

.amountControls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.amountButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 5px;
}

.amountButton:hover {
  background-color: #0056b3;
}

.amountInput {
  width: 100px;
  text-align: center;
  font-size: 1rem;
  border-radius: 5px;
  padding: 5px;
  border-style: none;
  outline: none;
}

.modalFooter {
  display: flex;
  justify-content: center;
}

.submitButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #218838;
}
