.percentage-width-container {
  position: relative;
  height: 8px;
  margin-top: 3px;
  border-radius: 15px;
  background-color: rgba(103, 82, 164, 0.1);
  overflow: hidden;
}

.colored-container {
  height: 100%;
  border-radius: 15px;
  background-color: #34a853;
  transition: width 0.4s ease-in-out;
}
